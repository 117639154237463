import { useEffect, useState } from "react";
import PageHeader from "../../../component/pageHeaders";
import { Col, Row,  } from "react-bootstrap";
import { Button, Form, Input, Select, message } from "antd";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";
import { POST } from "../../../utils/apirequest";
import { API } from "../../../config/API";
import { useSelector } from "react-redux";

function CreateUser() {
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState([]) as any;
  const [emailError, setEmailError] = useState("");
  const [userError, setUserError] = useState("");
  const User = useSelector((state: any) => state.User.user);
  const navigate = useNavigate();
  const { Option } = Select;

  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    let url = API.BASE_URL + API.USER_ROLE;
    axios.get(url).then((response) => {
      setUserRole(response.data.roles);
    });
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let body = {
      username: values.user.name,
      email: values.user.email,
      password: values.user.password,
      role: values.user.role,
      phone_number: values.user.phone_number,
    };
    let url = API.CREATE_USER;
    try {
      const response: any = await POST(url, body);
      if (response.username) {
        setLoading(false);
        navigate("/Auth/users");
        message.success("Created User SucessFully");
      } else {
        setLoading(false);
        message.error("Something went wrong....!");
      }
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong......!");
    }
  };
  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
  };

  const validatePassword = (rule: any, value: any, callback: any) =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?]).{8,}$/.test(
      value
    )
      ? callback()
      : callback(
          "Password must contain at least 8 characters, including uppercase and lowercase letters, numbers, and special characters."
        );

  const onchangeEmail = (event: any) => {
    let body = {
      email: event.target.value,
      username: event.target.value,
    };
    let Url = API.BASE_URL + API.GET_USERSEARCH;
    axios.post(Url, body).then((response) => {
      if (response.data.email.length) {
        setEmailError("This Email already exists");
      } else {
        setEmailError("");
      }
    });
  };
  const onchangeUser = (event: any) => {
    const username = event.target.value;

    const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(username);

    if (!isAlphanumeric) {
      setUserError("Name can only contain alphanumeric characters!");
      return;
    }
    let body = {
      email: event.target.value,
      username: event.target.value,
    };
    let Url = API.BASE_URL + API.GET_USERSEARCH;
    axios.post(Url, body).then((response) => {
      if (response.data.username.length) {
        setUserError("This Username already exists");
      } else {
        setUserError("");
      }
    });
  };

  return (
    <div>
      <PageHeader title={"Create Users"} />
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <div className="container-Centered">
          <div style={{ width: "80%" }}>
            <Row>
              <Col md={6} xs={6}>
                <Form.Item
                  name={["user", "name"]}
                  label="User Name"
                  {...formItemLayout}
                  validateStatus={userError ? "error" : ""}
                  help={userError}
                >
                  <Input onChange={onchangeUser} />
                </Form.Item>
              </Col>
              <Col md={6} xs={6}>
                <Form.Item
                  name={["user", "email"]}
                  label="Email"
                  {...formItemLayout}
                  validateStatus={emailError ? "error" : ""}
                  help={emailError}
                >
                  <Input onChange={onchangeEmail} required />
                </Form.Item>
              </Col>
              <Col md={6} xs={6}>
                <Form.Item
                  name={["user", "phone_number"]}
                  label="Phone number"
                  {...formItemLayout}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  name={["user", "role"]}
                  label="Role"
                  // rules={[{ message: "Role is required" }]}
                  {...formItemLayout}
                >
                  <Select placeholder="Select Role">
                    <Select.Option value={4}>Admin</Select.Option>
                    <Select.Option value={3}>Reporter</Select.Option>
                    <Select.Option value={5}>Contributer</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} xs={6}>
                <Form.Item
                  name={["user", "password"]}
                  label="Password"
                  rules={[{ validator: validatePassword }]}
                  {...formItemLayout}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col md={6} xs={6}>
                <Form.Item
                  name={["user", "confirm"]}
                  label="Confirm Password"
                  dependencies={["password"]}
                  rules={[
                    { required: true, message: "Please input your password!" },
                    {
                      min: 8,
                      message: "Passwordconfirm your passwords!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const password = getFieldValue(["user", "password"]);
                        if (!value || password === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(`please confirm your password`)
                        );
                      },
                    }),
                  ]}
                  {...formItemLayout}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs="7"></Col>
              <Col xs="2">
              <Button size="large" block onClick={() =>navigate(-1)}>
                Cancel
              </Button>
              </Col>
              <Col xs="2">
              <Button
              block
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Submit
              </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default CreateUser;
