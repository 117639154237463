import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DynamicIcon from "../../routes/DynamicIcon";

function DashboardScreen() {
  const User = useSelector((state: any) => state.User.user);
  const navigate = useNavigate();
  var content: any = [
    {
      head: "News",
      discription: "News Section",
      navigate: "/Auth/home",
      icon: "BiNews",
      role: [3, 4],
    },
    {
      head: "User",
      discription: "user Section",
      navigate: "/Auth/users",
      icon: "FiUsers",
      role: [4],
    },
    {
      icon: "BiCategoryAlt",
      head: "Categories",
      discription: "Catogory Section",
      navigate: "/Auth/categories",
      role: [4],
    },
    {
      icon: "TbLayersSubtract",
      head: "Sub Categories",
      discription: "Sub Categories Section",
      navigate: "/Auth/SubCategory",
      role: [4],
    },
    {
      icon: "BiCategoryAlt",
      head: "Tags",
      discription: "Tags Collections",
      navigate: "/Auth/tags",
      role: [3, 4],
    },
  ];
  return (
    <Container fluid={false}>
      <br />
      <div className="DashboardScreen-txt1">Good Morning,</div>
      <div className="DashboardScreen-txt2">Welcome to The Dashboard</div>
      <br />
      <Row>
        {content?.map((item: any) => {
          return (
            <Col
              sm={3}
              key={item.head}
              onClick={() => navigate(item?.navigate)}
            >
              <div className="DashboardScreen-item">
                <div>
                  <DynamicIcon name={item.icon} size={30} color={"#DAA520"} />
                </div>
                &nbsp; &nbsp;
                <div>
                  <div className="DashboardScreen-itemtxt1">{item?.head}</div>
                  <div className="DashboardScreen-itemtxt2">
                    {item?.discription}
                  </div>
                </div>
              </div>
            </Col>
          ) 
        })}
      </Row>
    </Container>
  );
}

export default DashboardScreen;
