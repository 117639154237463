import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: process.env.accessKeyId,
  secretAccessKey: process.env.secretAccessKey,
  region: process.env.region,
});

const bucket = "bairuha-bucket/";
export const uploadImageToS3 = (file: File, filename: string) => {
  let fileLabel = filename.replace(/\s+/g, "_");
  const params = {
    Bucket: bucket,
    Key: fileLabel,
    Body: file,
    ACL: "public-read",
  };
  return s3.upload(params).promise();
};

export const getImagesFromS3bucket = async (bucketName: string) => {
  const allData: AWS.S3.Object[] = [];
  let continuationToken: string | undefined = undefined;

  while (true) {
    const params: AWS.S3.ListObjectsV2Request = {
      Bucket: bucketName,
      ContinuationToken: continuationToken,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();

      allData.push(...(data.Contents || []));

      if (!data.IsTruncated) {
        break;
      }

      continuationToken = data.NextContinuationToken;
    } catch (error) {
      console.error("Error:", error);
      return error;
    }
  }
  const sortedData = allData.sort((a, b) => {
    const dateA = a.LastModified ?? new Date(0); // Use a default date if LastModified is undefined
    const dateB = b.LastModified ?? new Date(0);

    return dateA < dateB ? 1 : -1;
  });

  return sortedData;
};
