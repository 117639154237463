import * as React from "react";
import { useState } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import AddsModal from "./addScreen";
import { BiEdit } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { Popconfirm, message } from "antd";
import { API } from "../../../config/API";
import { DELETE } from "../../../utils/apirequest";
function Picker(props: any) {
  let apiMode = props.value && props?.value?.id ? "PUT" : "POST";
  const [picker, setPicker] = useState(false);

  const createAdd = () => {
    try {
    } catch (err) {}
  };

  const close = () => {
    setPicker(false);
    props.refresh();
  };

  const deleteAd = async (id:any) => {
    let URL = API.ADVERTISEMENT_DELETE + id
    let res :any = await DELETE(URL)
    if(res.data.id){
      message.success("Deleted Successfully")
      props.refresh();
    }else{
      message.error("Something went wrong...")
    }
  }

  return (
    <>
      <div className="addScreen-picker" style={props?.style}>
        {props.value && props?.value?.type ? (
          <div className="addScreen-pickerIcon">
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteAd(props?.value?.id)}
            >
              <IoTrashOutline color="red" size={20} cursor={"pointer"} /> &nbsp;
            </Popconfirm>
            <BiEdit
              size={20}
              color="#f5a442"
              cursor={"pointer"}
              onClick={() => setPicker(true)}
            />
          </div>
        ) : null}
        {props.value && props?.value?.type ? (
          props?.value?.type === "custom" ? (
            <img
              src={props?.value?.desktop}
              className={"addScreen-homeImage1"}
            />
          ) : (
            <div>
              Google Ads <br />
              {props?.value?.iframe}
            </div>
          )
        ) : (
          <div className="addScreen-pickerBox" onClick={() => setPicker(true)}>
            <IoIosAddCircleOutline size={30} color="grey" />
            <div>Select Add</div>
          </div>
        )}
      </div>
      {picker ? (
        <AddsModal
          mode={apiMode}
          visible={picker}
          value={props}
          close={() => close()}
        />
      ) : null}
    </>
  );
}

export default Picker;
