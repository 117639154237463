import type { ColumnsType } from "antd/es/table";
import { Pagination, Card, Table, Popover, Button, Popconfirm } from "antd";
import moment from "moment";
import { MdDeleteOutline, MdOutlineModeEdit } from "react-icons/md";

function SubCategoryscreenDataTable(props: any) {
  const ContentSub = (data: any) => {
    const confirm = (id: any) => {
      props.delete(id);
    };

    return data.data.map((item: any) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "70%" }}>{item.name}</div>
            <div style={{ marginLeft: "10px", cursor: "pointer" }}>
              <MdOutlineModeEdit size={20} onClick={() => props.Edit(item)} />
            </div>
            <div style={{ marginLeft: "10px", cursor: "pointer" }}>
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                onConfirm={() => confirm(item?.id)}
                okText="Yes"
                cancelText="No"
              >
                <MdDeleteOutline size={20} color="red" />
              </Popconfirm>
            </div>
          </div>
          <br />
        </>
      );
    });
  };
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Category",
      dataIndex: "attributes",
      key: "category",
      width: 500,
      render: (text, record: any) => {
        return <div style={{ fontSize: "10px" }}>{text.category}</div>;
      },
    },
    {
      title: "Sub Category",
      dataIndex: "attributes",
      key: "category",
      width: 500,
      render: (text, record: any) => {
        const data = text?.subcategories?.data?.map((item: any) => {
          return {
            id: item.id,
            name: item.attributes.name,
            category: record.attributes.category,
            categoryId: record.id,
            publishedAt: item.attributes.publishedAt,
          };
        });
        return (
          <Popover
            placement="topLeft"
            content={
              text?.subcategories?.data?.length ? (
                <ContentSub data={data} />
              ) : (
                "No Sub Category"
              )
            }
            trigger="hover"
          >
            <Button>{text?.subcategories?.data?.length || data}</Button>
          </Popover>
        );
      },
    },
    {
      title: "CreatedAt",
      dataIndex: "attributes",
      key: "createdAt",
      width: 500,
      render: (text, record: any) => {
        const createdAt = moment(text.createdAt).format("LL");
        return <div style={{ fontSize: "10px" }}>{createdAt}</div>;
      },
    },
    {
      title: "publishedAt",
      dataIndex: "attributes",
      key: "publishedAt",
      width: 500,
      render: (text, record: any) => {
        const PublishedAt = moment(text.createdAt).format("LL");
        return <div style={{ fontSize: "10px" }}>{PublishedAt}</div>;
      },
    },
  ];

  return (
    <div className="">
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ textAlign: "end" }}>
        <Pagination
          responsive
          defaultCurrent={props.meta && props.meta.page}
          total={props.meta && props.meta.total}
          pageSize={props.meta && props.meta.pageSize ? props.meta.pageSize : 0}
          onChange={props.onPageChange}
        />
      </div>
    </div>
  );
}

export default SubCategoryscreenDataTable;
