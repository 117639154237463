import * as React from "react";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles.css";
import Picker from "../components/picker";
import { API } from "../../../config/API";
import { POST } from "../../../utils/apirequest";
function NjayarDetailed() {
  const [section1, setSection1] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let obj = {
        screen: "njayarDetails",
      };
      let url = API.ADVERTISEMENT_LIST;
      let res: any = await POST(url, obj);
      if (res.status) {
        let sec1 = res.data.find(
          (item: any) => item.section === "section1"
        );
        setSection1(sec1);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          <div className="addScreen-Detaild">Njayar</div>
        </Col>
        <Col md={4}>
          <br />
          <Picker
            value={section1}
            screen={"home"}
            field={"top"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 150 }}
            refresh={() => loadData()}
          />
          <br />
          <div className="addScreen-Related">Related Njayar</div>
        </Col>
      </Row>
      <br />
    </Container>
  );
}

export default NjayarDetailed;
