import { useState } from "react";
import { Button, Form, Input, Select, Spin } from "antd";
import { Col, Row, message } from "antd";
import PageHeader from "../../../component/pageHeaders";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API } from "../../../config/API";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';

function UpdateUser() {
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [userError, setUserError] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const userId = location?.state?.item?.id

    const onFinish = async (values: any) => {
        setLoading(true);
        let body = {
            username: values.name,
            email: values.email,
            ...(values.password ? { password: values.password } : {}),
            role: values.role,
            phone_number: values.phone_number
          };
          
        let url = API.BASE_URL + API.PUT_UPDATE_USER + `${userId}` ;
        try {
            const response = await axios.put(url, body);
          if (response.data.id) {
            setLoading(false);
            navigate("/Auth/users");
            message.success("Update User deatails SucessFully" );
          } else {
            setLoading(false);
            message.error("Something went wrong....!");
          }
        } catch (error) {
          setLoading(false);
          message.error("Something went wrong......!");
        }
      };

      const validateMessages = {
        required: "${label} is required!",
        types: {
          email: "${label} is not a valid email!",
          number: "${label} is not a valid number!",
        },
        number: {
          range: "${label} must be between ${min} and ${max}",
        },
      };
    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 20 },
      };

      const initialValues = {
        name: location?.state?.item?.username,
        email: location?.state?.item?.email,
        phone_number: location?.state?.item?.phone_number,
        role: location?.state?.item?.role.id,
      };
      const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />;

      const onchangeEmail = (event: any) => {
        let body = {
          email: event.target.value,
          username: event.target.value,
        };
        let Url = API.BASE_URL + API.GET_USERSEARCH;
        axios.post(Url, body).then((response) => {
          if (response.data.email.length) {
            setEmailError('This Email already exists');
          } else {
            setEmailError("")
          }
        });
      };
      const onchangeUser = (event: any) => {
        let body = {
          email: event.target.value,
          username: event.target.value,
        };
        let Url = API.BASE_URL + API.GET_USERSEARCH;
        axios.post(Url, body).then((response) => {
          if (response.data.username.length) {
            setUserError('This Username already exists');
          } else {
            setUserError("")
          }
        });
      };
  return (
    <div>
    <PageHeader
      title={location.state.head}
    />
    <Form
      layout="vertical"
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
  
        <div className="container-Centered">
          <div style={{ width: "80%" }}>
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  name="name"
                  label="User Name"
                  {...formItemLayout}
                  validateStatus={userError ? "error" : ""}
                  help={userError}
                >
                  <Input size="large" onChange={onchangeUser} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="email"
                  label="Email"
                  {...formItemLayout}
                  validateStatus={emailError ? "error" : ""}
                  help={emailError}
                >
                  <Input size="large" onChange={onchangeEmail}/>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  name="phone_number"
                  label="Phone number"
                  {...formItemLayout}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  name="role"
                  label="Role"
                  // rules={[{ message: "Role is required" }]}
                  {...formItemLayout}
                >
                  <Select placeholder="Select Role" size="large">
                    <Select.Option value={4}>Admin</Select.Option>
                    <Select.Option value={3}>Reporter</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} xs={12}>
                <Form.Item
                  name="password"
                  label="Password"
                  {...formItemLayout}
                >
                  <Input.Password size="large" />
                </Form.Item>
              </Col>
              <Col md={10} xs={12}>
              <Form.Item
                  label="."
                  labelCol={{ style: { color:"transparent" } }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="editProfile-Bttn"
                  >
                    {loading ? <Spin indicator={antIcon} /> : "Update"}
                  </Button>
                  </Form.Item>
                </Col>
            </Row>
          </div>
        </div>
    </Form>
    </div>
  )
}

export default UpdateUser