import React, { useState, useEffect } from "react";
import "./styles.css";
import { Layout, theme } from "antd";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { logout } from "../redux/slices/userslice";

import Header from "./Header";
import Siders from "./sider";

import DashboardScreen from "../screens/dashboardScreen";
import HomeScreen from "../screens/newsScreen";
import UserScreen from "../screens/userscreen";
import CategoryScreen from "../screens/categoryscreen";
import CreateUser from "../screens/userscreen/components/createUser";
import NewForm from "../screens/newsScreen/component/newform";
import CategoryForm from "../screens/categoryscreen/component/categoryForm";
import ProfileScreen from "../screens/profileScreen";
import EditProfileScreen from "../screens/profileScreen/component/editForm";
import { useNavigate } from "react-router";
import UpdateUser from "../screens/userscreen/components/updateUser";
import ChangePassword from "../screens/profileScreen/component/changePassword";
import SubCategoryscreen from "../screens/subCategoryscreen";
import TagScreen from "../screens/tagsScreen";

import AddScreen from "../screens/addScreen";

function Routing() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [userActivity, setUserActivity] = useState(false);

  const navigate: any = useNavigate();
  const dispatch = useDispatch();

  const resetUserActivity = () => {
    setUserActivity(false);
  };

  useEffect(() => {
    const handleUserActivity = () => {
      setUserActivity(true);
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);

    const timeoutId = setTimeout(() => {
      if (!userActivity) {
        dispatch(logout(""));
        navigate("/");
      }
      resetUserActivity();
    }, 3600000);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
    };
  }, [userActivity]);

  return (
    <Layout>
      <Layout>
        <Siders />
        <Layout style={{ backgroundColor: "#fff" }}>
          <Header />
          <div className="routeBox">
            <Routes>
              <Route path="/" element={<DashboardScreen />} />
              <Route path="/home" element={<HomeScreen />} />
              <Route path="/home/news" element={<NewForm />} />
              <Route path="/home/news/:id" element={<NewForm />} />
              <Route path="/users" element={<UserScreen />} />
              <Route path="/users/form" element={<CreateUser />} />
              <Route path="/categories" element={<CategoryScreen />} />
              <Route path="/categories-form" element={<CategoryForm />} />
              <Route path="/tags" element={<TagScreen />} />
              <Route path="/profile" element={<ProfileScreen />} />
              <Route path="/editprofile" element={<EditProfileScreen />} />
              <Route path="/updateuser/form" element={<UpdateUser />} />
              <Route path="/ChangePassword/form" element={<ChangePassword />} />
              <Route path="/SubCategory" element={<SubCategoryscreen />} />
              <Route path="AddScreen" element={<AddScreen />} />
            </Routes>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Routing;
