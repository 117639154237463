import React from "react";
import { Button, Modal } from "antd";
import { Row, Col } from "react-bootstrap";

function ActionModal(props: any) {
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.onClose()}
      destroyOnClose={true}
      footer={false}
      centered
      closable={true}
      width={400}
    >
      <div className="ActionModal-box1">
        {props.icon}
        <div style={{margin:5}}/>
        <div>{props.text1}</div>
        <br />
      </div>
        <Row>
          <Col sm={6} xs={6}>
            <Button size="large" block onClick={() => props.onClose()} danger>Cancel</Button>
          </Col>
          <Col sm={6} xs={6}>
            <Button size="large" block type="primary" onClick={() => props.onOk()}>
              Logout
            </Button>
          </Col>
        </Row>
    </Modal>
  );
}

export default ActionModal;
