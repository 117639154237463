import { Modal, Button, Tag } from "antd";
import React, { useState } from "react";
import "./styles.css";
import moment from "moment";
import ReactDOM from "react-dom";
import { BiMobile } from "react-icons/bi";
import { FaDesktop, FaUserCircle } from "react-icons/fa";
import { FaTabletAlt } from "react-icons/fa";
import { MdOutlineOpenInNew } from "react-icons/md";
import WebPreview from "../../screens/webScreenPreview";
import { IoLogoWhatsapp } from "react-icons/io";
import { CiBookmark } from "react-icons/ci";
import { RiTwitterFill } from "react-icons/ri";
import { FiMoreVertical } from "react-icons/fi";

const PreviewModal = (props: any) => {
  const [modalWidth, setModalWidth] = useState(990);

  const handleChange = (newWidth: number) => {
    setModalWidth(newWidth);
  };

  const openNewTab = () => {
    localStorage.setItem("previewdata", JSON.stringify(props?.data));
    const url = `/WebPreview`;
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.document.addEventListener("DOMContentLoaded", () => {
        const rootElement = newTab.document.createElement("div");
        newTab.document.body.appendChild(rootElement);
        ReactDOM.render(<WebPreview />, rootElement);
      });
    }
  };

  return (
    <Modal
      open={props.open}
      closable={props.closable}
      footer={props.footer}
      onCancel={props.onCancel}
      width={modalWidth}
      title={
        <div className="PreviewModal-box1">
          <MdOutlineOpenInNew onClick={openNewTab} />
          <FaDesktop
            color={modalWidth === 990 ? "#DAA520" : "#000"}
            onClick={() => handleChange(990)}
          />
          <FaTabletAlt
            color={modalWidth === 786 ? "#DAA520" : "#000"}
            onClick={() => handleChange(786)}
          />
          <BiMobile
            color={modalWidth === 420 ? "#DAA520" : "#000"}
            onClick={() => handleChange(420)}
          />
          <Button size="small" type="primary" onClick={()=>props?.preview()}>
            Website Preview
          </Button>
        </div>
      }
    >
      <br />
      <div style={{ margin: -10 }}>
        {props?.data?.map((item: any) => {
          return (
            <div key={item.id}>
              <div className="PreviewModal-box1">
                <div style={{ flex: 1 }}>
                  {item?.tag
                    ? item.tag.map((tag: any) => <Tag key={tag}>{tag}</Tag>)
                    : null}
                  <Tag>Tags</Tag><Tag>Tags</Tag>
                </div>
                <div>
                  <Button>Aa</Button>
                  &nbsp;
                  <Button>MAL</Button>
                </div>
              </div>
              <hr />
              {props.data ? (
                <div>
                  <div className="PreviewModal-txt1">{item.head}</div>
                  {item.title ? (
                    <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                  ) : null}
                  <br />
                  <div className="PreviewModal-box1">
                    <FaUserCircle size={25} color="grey" />
                    <div className="PreviewModal-box2">
                      {item?.byline ? <div>{item?.byline}</div> : null}
                      <div>{moment().format("dddd,MMMM Do, h:mm:a")}</div>
                    </div>
                    <div className="PreviewModal-box1">
                      <CiBookmark color="black" />
                      <IoLogoWhatsapp />
                      <RiTwitterFill />
                      <FiMoreVertical />
                    </div>
                  </div>
                  <br />
                  <img
                    src={item.image ? item.image : ""}
                    className="PreviewModal-img"
                    alt=""
                  />
                  <br />
                  {item.body ? (
                    <div style={{fontSize:18}} dangerouslySetInnerHTML={{ __html: item.body }}></div>
                  ) : null}
                </div>
              ) : (
                <div>NO DATA FOUND....</div>
              )}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default PreviewModal;
