import React, { useEffect, useState } from "react";
import { Modal, Input } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { getImagesFromS3bucket } from "../../config/s3config/s3fileUpload";
import Loadingbox from "../loadingbox";
import { IoCloseCircleOutline } from "react-icons/io5";

const { Search } = Input;
function MediaLibrary(props: any) {
  
  useEffect(() => {
    loadData();
  }, []);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [result, setResult] = useState([]);

  const loadData = async () => {
    setLoading(true);
    try {
      const response: any = await getImagesFromS3bucket("suprabhaatham-bucket");
      if (response) {
        const result = response;
        setData(result);
        setResult(result)
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const search = (val: any) => {
    let key = val.target.value
    if(key.length > 2){
      setLoading(true);
      const result = data.filter((item: any) => {
        return item.Key.includes(key);
      });
      setResult(result);
      setLoading(false);
    }
  };

  return (
    <Modal visible={props.visible} onCancel={props.onCancel} width={1500}>
      <Container>
        <div className="MediaLibrary-txt1">Media Library</div>
        <Search
          placeholder="Search image"
          onChange={search}
          suffix={
            <span onClick={() => loadData()}>
              <IoCloseCircleOutline size={20} color="gray" />
            </span>
          }
        />
        {loading ? (
          <Loadingbox />
        ) : (
          <Row>
            {result.map((item: any, index) => {
              let imageUrl = `https://suprabhaatham-bucket.s3.ap-south-1.amazonaws.com/${item.Key}`;
              return (
                <Col sm="2" xs="6" style={{ padding: 10 }}>
                  <img
                    className="MediaLibrary-image"
                    src={imageUrl}
                    onClick={() => props.onSelect(imageUrl)}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Container>
    </Modal>
  );
}

export default MediaLibrary;
