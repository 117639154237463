import { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./styles.css";
import PageHeader from "../../component/pageHeaders";
import { Select, message, DatePicker, Button, Input, Tag } from "antd";
import DataTable from "./component/datatable";
import Loadingbox from "../../component/loadingbox";
import { API } from "../../config/API";
import { useNavigate } from "react-router-dom";
import { POST } from "../../utils/apirequest";
import { LoadCategories } from "./utils/apiRequest";
import NewsLog from "../../component/newsLog";
import OverWrite from "../../component/overwrite";
import moment from "moment";

function HomeScreen() {
  const [logShow, setLOgShow] = useState(false);
  const [overWrite, setOverWrite] = useState(false);
  const [isloading, setIsloading] = useState<boolean>(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [data, setData] = useState([]) as any;
  const [meta, setMeta] = useState() as any;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [usersList, setusersList] = useState<any[]>([]);
  const [trashcount, setTrashcount] = useState();
  const [draft, setDraft] = useState();
  const [publishCount, setPublishCount] = useState();
  const [singlevalue, setSinglevalue] = useState<any>();
  const [overWriteData, setoverWriteData] = useState<any>();
  const [user, setuser] = useState("");
  const navigation = useNavigate();

  useEffect(() => {
    loadData(filter);
    GET_USERS();
    loadCategories();
  }, [user]);

  const GET_USERS = () => {
    let url = API.BASE_URL + API.GET_USERS;
    axios.get(url).then((response) => {
      setusersList(response.data);
    });
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      loadData(filter);
    }, 100);
  }, []);

  const loadData = async (filter: any) => {
    setIndicator(true)
    let URL = API.GET_NEWS;
    try {
      const res: any = await POST(URL, filter);
      if (res.status) {
        setData(res.data);
        setPublishCount(res.publishedCount);
        setTrashcount(res.trashedCount);
        setDraft(res.draftCount);
        setMeta(res.totalCount);
        setIsloading(false);
        setIndicator(false)
      } else {
        setIsloading(false);
        setIndicator(false)
      }
    } catch (err) {
      setIsloading(false);
      setIndicator(false)
      console.log("err", err);
      message.error("Something went wrong...");
    }
  };

  const loadCategories = async () => {
    const res: any = await LoadCategories();
    setCategoryList(res.data);
  };

  const onEdit = (val: any) => {
    if (val.editing_Status) {
      setOverWrite(true);
      setoverWriteData(val);
    } else {
      navigation(`/Auth/home/news/${val.id}`, { state: { item: val.onlive } });
    }
  };

  const CategoryChange = (category: any) => {
    let filters = {
      ...filter,
      catId: category,
    };
    setFilter(filters);
    loadData(filters);
  };

  const PriorityChange = (prio:any) => {
    let filters = {
      ...filter,
      isHighPriority: prio,
    };
    setFilter(filters);
    loadData(filters);
  }

  const LoadByUser = (id: any) => {
    let filters = {
      ...filter,
      userId: id,
    };
    setFilter(filters);
    loadData(filters);
  };

  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };

  const LoadPublished = () => {
    let filters = {
      ...filter,
      type: "published",
    };
    setFilter(filters);
    loadData(filters);
  };

  const LoadTrashed = () => {
    let filters = {
      ...filter,
      type: "trash",
    };
    setFilter(filters);
    loadData(filters);
  };

  const LoadDraft = () => {
    let filters = {
      ...filter,
      type: "draft",
    };
    setFilter(filters);
    loadData(filters);
  };


  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : (page - 1) * 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const addToTrash = (val: any) => {
    let url = API.BASE_URL + API.ADD_TO_TRASH + `/${val.id}`;
    axios
      .put(url)
      .then((response) => {
        loadData(filter);
        message.success("sucesssfully moved to trash");
      })
      .catch((error) => {
        message.error(error.message);
        console.log(error);
      });
  };

  const UndoTrash = (val: any) => {
    let url = API.BASE_URL + API.UNTRASH + `/${val.id}`;
    axios
      .put(url)
      .then((response) => {
        loadData(filter);
      })
      .catch((error) => {
        message.error(error.message);
        console.log(error);
      });
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };

  const onlogShow = (val: any) => {
    setLOgShow(true);
    setSinglevalue(val);
  };

  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"News"} loader={indicator} length={`${meta} News`}>
        <div className="homescreen-pageItem">
          <div style={{ width: 250 }}>
            <Input
              allowClear
              placeholder="Search News "
              onChange={(e: any) => Search(e.target.value)}
            />
          </div>
          &nbsp;
          <div className="homescreen-pageBox">
            <div
              className="homescreen-pageItem"
              onClick={() => LoadPublished()}
            >
              <span style={{ color: "green" }}>Published</span> : &nbsp;
              <b>{publishCount}</b>
            </div>
            |
            <div className="homescreen-pageItem" onClick={() => LoadTrashed()}>
              <span style={{ color: "red" }}>Trashed</span> : &nbsp;
              <b>{trashcount}</b>
            </div>
            |
            <div className="homescreen-pageItem" onClick={() => LoadDraft()}>
              <span style={{ color: "#DAA520" }}>Draft</span> : &nbsp;
              <b>{draft}</b>
            </div>
          </div>
          &nbsp;
          <Button type="primary" onClick={() => navigation("/Auth/home/news")}>
            News Entry +
          </Button>
        </div>
      </PageHeader>
      <div style={{ padding: 5 }} />
      <div className="homescreen-pageItem">
      <div style={{ flex: 1,flexDirection:"row",display:"flex",flexWrap:"wrap" }}>
        {Object.keys(filter)?.length > 2 ?"Filter By :":"All News"} &nbsp;
        {Object.keys(filter)?.map(
          (key, index) =>
            key !== "start" &&
            key !== "limit" && <Tag style={{marginBottom:5}}>{`${key}:  ${ key === "createdAt" ? moment(filter[key]).format("DD-MM-YYYY")  :filter[key]}`} <CloseCircleOutlined onClick={() => clearFilters(key)} /></Tag>
        )}
        </div>
        <div style={{ flex: 1 }}>
          <DatePicker
            allowClear
            style={{ width: 200 }}
            onChange={onChangeDate}
          />
          &nbsp;
          <Select
            placeholder="Created By"
            onChange={(val) => LoadByUser(val)}
            showSearch={true}
            style={{ width: 270 }}
            allowClear
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {usersList?.length > 0 &&
              usersList?.map((item: any, index: any) => {
                return (
                  <Select.Option key={index} value={item.id}>
                    {item.username}
                  </Select.Option>
                );
              })}
          </Select>
          &nbsp;
          <Select
            showSearch={true}
            placeholder="Category"
            onChange={(val) => CategoryChange(val)}
            style={{ width: 200 }}
            allowClear
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categoryList?.length > 0 &&
              categoryList?.map((item: any, index: any) => {
                return (
                  <Select.Option key={index} value={item.id}>
                    {item.attributes.category}
                  </Select.Option>
                );
              })}
          </Select>
          &nbsp;
          <Select
            showSearch={true}
            placeholder="Priority"
            onChange={(val) => PriorityChange(val)}
            style={{ width: 200 }}
            allowClear
          >
          <Select.Option key="high">High Priority</Select.Option>
          <Select.Option key={"second"}>Second Lead</Select.Option>
          </Select>
        </div>
      </div>
      {isloading ? (
        <Loadingbox />
      ) : (
        <DataTable
          data={data && data}
          meta={meta}
          page={page}
          pagesize={pageSize}
          refresh={() => loadData(filter)}
          onEdit={(val: any) => onEdit(val)}
          onlog={(val: any) => onlogShow(val)}
          onPageChange={(page: any, pageSize: any) =>
            onPageChange(page, pageSize)
          }
          onTrash={(val: any) => addToTrash(val)}
          unTrash={(val: any) => UndoTrash(val)}
          onData={(val: any) => {
            setuser(val);
          }}
        />
      )}
      {logShow ? (
        <NewsLog
          visible={logShow}
          id={singlevalue}
          close={() => setLOgShow(false)}
        />
      ) : null}
      {overWrite ? (
        <OverWrite
          visible={overWrite}
          data={overWriteData}
          close={() => setOverWrite(false)}
        />
      ) : null}
      <br />
      <br />
    </div>
  );
}

export default HomeScreen;
