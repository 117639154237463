import axios from "axios";
import { API } from "../../../config/API";
import moment from "moment";
import { notification } from "antd";

const CreateNews = (values: any, body: any, editorID: any) => {
  const tagsfilteredArray = values?.tags?.join("|");
  const selectedCategories = values?.categories?.map((value: any) =>
    JSON.parse(value)
  );

  let categories = selectedCategories?.map((item: any) => {
    return item.id;
  });

  let CatArray = selectedCategories
    ?.map((item: any) => "%" + item?.category?.toLowerCase())
    .join("");
  let show;
  if (body?.onlive === true && values?.Show === undefined) {
    show = true;
  }
  let requestObj = {
    data: {
      title: body.blurb,
      head: values?.head,
      body: body.body,
      image: body.image,
      image2: body.image2,
      social_image: body.social_image,
      categories: categories,
      Subcategories: values.subcategory,
      tagsArray: CatArray,
      newsTag: tagsfilteredArray,
      publishedAt: body.publishedAt,
      audio: body.audio ? body.audio : null,
      user: body?.user,
      onlive: values?.Show === true ? values.Show : show || false,
      isHighPriority: values?.isHighPriority === 1 ? true : false,
      isSecondLeads: values?.isHighPriority === 2 ? true : false,
      permalink: values?.permalink,
      editing_Status: false,
      liveEditingUser: "null",
      image1_description: body?.image1_description,
      image2_description: body?.image2_description,
      image1_caption: body?.image1_caption,
      author_name: values?.author_name,
      author_image: body.author_image ? body.author_image : null,
      publish_at: values?.publish_time
        ? moment(values?.publish_time?.$d).format("YYYY-MM-DD HH:mm")
        : null,
    },
  };
  let id = body.id;
  let url = id
    ? API.BASE_URL + API.UPDATE_NEWS + `/${id}/${editorID}`
    : API.BASE_URL + API.CREATENEWS;
  let method = id ? "PUT" : "POST";
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObj),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
        notification.success({
          message: `News ${values.type}`,
        });
        addTags(values?.tags);
      })
      .catch((error) => {
        reject(error);
        notification.success({
          message: error,
        });
      });
  });
};

const getNewsById = (url: any) => {
  return new Promise(async (resolve, reject) => {
    axios
      .get(url)
      .then((response) => response)
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const EditNews = (id: any, body: any) => {
  let url = API.BASE_URL + API.ALL_NEWS + `/${id}`;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const PublishNews = (id: any) => {
  let url = API.BASE_URL + API.PUBLISH_NEWS + `/${id}`;
  return new Promise(async (resolve, reject) => {
    try {
      fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      console.log("error", error);
    }
  });
};

const SaveDraft = (id: any, data: any) => {
  let url = id
    ? API.BASE_URL + API.ALL_NEWS + `/${id}`
    : API.BASE_URL + API.CREATENEWS;
  let method = id ? "PUT" : "POST";
  if (!id) {
    setTimeout(() => {
      return new Promise(async (resolve, reject) => {
        fetch(url, {
          method: method,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((json) => {
            resolve(json);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }, 2000);
  } else {
    console.log("updating logic");
  }
};

const addTags = (val: any) => {
  try {
    let url = API.BASE_URL + API.TAGS;
    val.forEach((tagValue: any) => {
      let requestObj = {
        data: {
          tag: tagValue,
        },
      };
      axios({
        method: "POST",
        url: url,
        data: requestObj,
      })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    });
  } catch (error) {
    console.log("error", error);
  }
};

const LoadTags = () => {
  let url = API.BASE_URL + API.GET_TAGS;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueIds = new Set();
        const combinedResults: any = [];
        data.forEach((item: any) => {
          if (!uniqueIds.has(item.tag)) {
            uniqueIds.add(item.tag);
            combinedResults.push(item);
          }
        });
        resolve(combinedResults);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const LoadCategories = () => {
  let url = API.BASE_URL + API.CATEGORY;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const LoadSubCategories = () => {
  let url =
    API.BASE_URL +
    API.SUBCATEGORY +
    `?populate[0]=category&pagination[limit]=100`;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  CreateNews,
  EditNews,
  PublishNews,
  SaveDraft,
  addTags,
  LoadTags,
  LoadCategories,
  LoadSubCategories,
  getNewsById,
};
