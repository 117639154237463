import { IoArrowBackCircleOutline } from "react-icons/io5";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
function PageHeader(props: any) {
  const navigation = useNavigate();
  return (
    <>
      <div className="PageHeaders">
        <div className="PageHeaders-box1" onClick={() => navigation(-1)}>
          <IoArrowBackCircleOutline />
        </div>
        <div className="PageHeaders-box2">
          {props?.title}
          {props.length ? (
            <div className="PageHeaders-txt1">{props.length}</div>
          ) : null}
        </div>
        <div style={{ flex: 1 }}></div>
        {props?.children ? <div>{props?.children}</div> : null}
      </div>
      {props.loader ? (
        <div style={{ marginBottom: 15 }}>
          <LinearProgress color={"primary"} style={{ height: 2 }} />
        </div>
      ) : null}
    </>
  );
}

export default PageHeader;
