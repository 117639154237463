import { Modal } from "antd";
import "./styles.css";
import Lock from "../../assets/image/lock.svg";
function Lockmodal(props: any) {
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      centered
      width={500}
      footer={false}
    >
      <div className="LoginScreen-Lockmodal">
        <img src={Lock} alt="lock" className="LoginScreen-Lockmodalimg" />
        <br/>
        <div className="LoginScreen-Lockmodaltxt1">
          Your Account Has Been Locked
        </div>
        <br/>
        <div className="LoginScreen-Lockmodaltxt2">
          {props.data}
        </div>
      </div>
    </Modal>
  );
}

export default Lockmodal;