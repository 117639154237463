import { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import CategoryDataTable from "./component/dataTable";
import { API } from "../../config/API";
import Loadingbox from "../../component/loadingbox";
import axios from "axios";
import { Button, DatePicker, Form, Input, Modal, Tag, message } from "antd";
import { Row, Col } from "react-bootstrap";
import { CloseCircleOutlined } from "@ant-design/icons";

import "./style.css";
import moment from "moment";

function CategoryScreen(props: any) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState<any>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState<any>();
  const [indicator, setIndicator] = useState<boolean>(true);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  useEffect(() => {
    loadData(filter);
  }, []);
  const loadData = (filter: any) => {
    let url = API.BASE_URL + API.GET_CATEGORY;
    axios.post(url, filter).then((response) => {
      let data = response.data.data;
      let meta = response.data.totalCount;
      setData(data);
      setMeta(meta);
      setIsLoading(false);
    });
  };
  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };

  const onFinish = async (values: any) => {
    let body = {
      data: {
        category: values.category,
        publishedAt: update?.id ? update?.published : new Date(),
      },
    };
    let url = update?.id
      ? API.BASE_URL + API.CATEGORY + `/${update?.id}`
      : API.BASE_URL + API.CATEGORY;
    let method = update?.id ? "put" : "post";
    axios({
      method: method,
      url: url,
      data: body,
    })
      .then((response) => {
        setIsLoading(false);
        setShow(false);
        loadData(filter);
        message.success("Sucessfully Created");
        form.resetFields();
        setUpdate("");
      })
      .catch((error) => {
        console.log(error);
        message.error("Please try again");
      });
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const onEdit = (val: any) => {
    let obj = {
      id: val.id,
      published: val.attributes.publishedAt,
    };
    setUpdate(obj);
    form.setFieldsValue({
      category: val.attributes.category,
    });
    setShow(true);
  };
  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };
  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };
  return (
    <div style={{ height: "90vh", overflow: "scroll" }}>
      <PageHeader title={"Categories"} length={meta}>
        <div className="homescreen-pageItem">
          <div style={{ width: 250 }}>
            <Input
              allowClear
              placeholder="Search category "
              onChange={(e: any) => Search(e.target.value)}
            />
          </div>
          &nbsp;
          <Button
            type="primary"
            onClick={() => {
              form.resetFields();
              setShow(true);
            }}
          >
            Add New +
          </Button>
        </div>
      </PageHeader>
      <div style={{ padding: 5 }} />
      <div className="homescreen-pageItem">
        <div
          style={{
            flex: 5,
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(filter)?.length > 2 ? "Filter By :" : "All Categories"}{" "}
          &nbsp;
          {Object.keys(filter)?.map(
            (key, index) =>
              key !== "start" &&
              key !== "limit" && (
                <Tag style={{ marginBottom: 5 }}>
                  {`${key}:  ${
                    key === "createdAt"
                      ? moment(filter[key]).format("DD-MM-YYYY")
                      : filter[key]
                  }`}{" "}
                  <CloseCircleOutlined onClick={() => clearFilters(key)} />
                </Tag>
              )
          )}
        </div>
        <div style={{ flex: 1 }}>
          <DatePicker
            allowClear
            style={{ width: 200 }}
            onChange={onChangeDate}
          />
        </div>
        &nbsp;
      </div>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <CategoryDataTable
          data={data}
          meta={meta}
          page={page}
          pagesize={pageSize}
          onPageChange={(page: any, pageSize: any) =>
            onPageChange(page, pageSize)
          }
          refresh={() => loadData(filter)}
          onEdit={(val: any) => onEdit(val)}
        />
      )}
      {show && (
        <>
          <Modal
            footer={false}
            open={show}
            onCancel={() => setShow(false)}
            centered
          >
            <div className="category-Modal">
              <div className="categoryScrn-Txt1"> Category</div>
              <Form
                layout="vertical"
                name="nest-messages"
                form={form}
                onFinish={onFinish}
              >
                <div style={{ marginTop: 30 }}>
                  <Form.Item name={"category"} {...formItemLayout}>
                    <Input placeholder="Type here....." size="large" />
                  </Form.Item>
                </div>
                <Row>
                  <Col xs="6"></Col>
                  <Col xs="3">
                    <Button block size="large" onClick={() => setShow(false)}>
                      Cancel
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      size="large"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default CategoryScreen;
