import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../component/pageHeaders";
import DataTable from "./components/datatable";
import { API } from "../../config/API";
import Loadingbox from "../../component/loadingbox";
import axios from "axios";
import { Button, Input, Select, Tag, message, notification } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { POST } from "../../utils/apirequest";
import moment from "moment";

function UserScreen() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [indicator, setIndicator] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [meta, setMeta] = useState() as any;
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;


  useEffect(() => {
    loadData(filter);
  }, []);


  const loadData = async (filter: any) => {
    setIndicator(true);
    let URL = API.GET_USERS_LIST;
    try {
      const res: any = await POST(URL, filter);
      if (res.status) {
        setData(res.data);
        setMeta(res.totalCount);
        setIsLoading(false);
        setIndicator(false)
      }else {
        setIsLoading(false);
        setIndicator(false)
      }
    } catch (err) {
      setIsLoading(false);
      setIndicator(false);
      console.log("err", err);
      message.error("Something went wrong...");
    }
  };

  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setTimeout(() => {
        loadData(filters);
      }, 500);
      setFilter(filters);
    }
  }

  const LoadByRole = (val:any) => {
    let filters = {
      ...filter,
      role: val,
    };
    setFilter(filters);
    loadData(filters);
  }

  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : (page - 1) * 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const deleteData = (id: any) => {
    setIsLoading(true);
    let url = API.BASE_URL + API.DELETE_USER + `${id}`;
    axios
      .delete(url)
      .then((response) => {
        message.success("Successfully deleted");
        loadData(filter);
      })
      .catch((err) => {
        console.log(err);
        message.destroy("Something went wrong...!");
      });
  };

  const updateData = async (id: any, checked: any) => {
    try {
      let requestObj = {
        blocked: checked,
      };
      let url = API.BASE_URL + API.USERS + `/${id}`;

      const response = await axios.put(url, requestObj);
      notification.success({
        message: checked ? "Inactive" : "Active",
        description: checked
          ? "Youser inactive success"
          : "Youser Active success",
      });
      loadData(filter);
    } catch (error) {
      notification.error({
        message: "sorry",
        description: "couldnt complete upadating user.",
      });
      console.error("An error occurred:", error);
    }
  };

  return (
    <div>
      <PageHeader title={"Users"} length={meta}  loader={indicator}>
          <Button type="primary" onClick={() => navigate("/Auth/users/form")}>
            Add New +
          </Button>
      </PageHeader>
      <div style={{ padding: 5 }} />
      <div style={{ flexDirection:"row",display:"flex",justifyContent:"space-between" }} >
        <div>
        {Object.keys(filter)?.length > 2 ?"Filter By :":"All Users"} &nbsp;
        {Object.keys(filter)?.map(
          (key, index) =>  key !== "start" &&
          key !== "limit" &&
          <Tag style={{marginBottom:5}}>{`${key}:  ${ key === "createdAt" ? moment(filter[key]).format("DD-MM-YYYY")  :filter[key]}`} <CloseCircleOutlined onClick={() => clearFilters(key)} /></Tag>
        )}
        </div>
        <div style={{ flexDirection:"row",display:"flex" }}>
          <Input
            style={{width:"250px"}}
            allowClear
            placeholder="Search Users"
            onChange={(e: any) => Search(e.target.value)}
          />
          &nbsp;
          <Select
            placeholder="Role"
            onChange={(val) => LoadByRole(val)}
            showSearch={true}
            style={{width:"200px"}}
            allowClear
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option key={4}>Admin</Select.Option>
            <Select.Option key={3}>Reporter</Select.Option>
            <Select.Option key={5}>Contributer</Select.Option>
          </Select>
        </div>
      </div>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <DataTable
          data={data}
          meta={meta}
          page={page}
          pagesize={pageSize}
          onDelete={(id: any) => deleteData(id)}
          onUpdate={(id: any, checked: any) => updateData(id, checked)}
          onPageChange={(page: any, pageSize: any) =>
            onPageChange(page, pageSize)
          }
          refresh={() => loadData(filter)}
        />
      )}
    </div>
  );
}

export default UserScreen;
