import React, { useState } from "react";
import { BsArrowLeftCircle } from "react-icons/bs";
import "./styles.css";
import { Button, Input, Space } from "antd";
import { Col, Row } from "react-bootstrap";

function PageHeader(props: any) {
  const handleSearch = (val: any) => {
    props.Search(val);
  };

  return (
    <>
      <div className="pageHeaderConatiner">
        <Row>
          <Col sm={6} xs={9} md={"6"}>
            <div style={{ display: "flex" }}>
              <div className="headerIconBox">
                <BsArrowLeftCircle
                  size={30}
                  color="#9BA9B6"
                  cursor={"pointer"}
                  onClick={() => props.goBack()}
                />
              </div>
              <span className="headerTitle">{props.title}</span>
            </div>
            {props.title === "Profile" ? (
              ""
            ) : (
              <span className="headerDiscription">
                Total {props.total} {props.item}
              </span>
            )}
          </Col>
          <Col
            sm="6"
            xs={3}
            md={{ span: 5, offset: 1 }}
            className="d-flex justify-content-end"
          >
            {props.buttontext ? (
              <Button
                type="primary"
                className="headerBtn"
                htmlType="submit"
                onClick={() => props.onBtnPress()}
                loading={props.loading}
              >
                {props.buttontext}
              </Button>
            ) : null}
            {/* <div style={{ paddingLeft: 6 }}>
              <Popover
                style={{ background: "yellow", height: 40, marginTop: 10 }}
                trigger={"click"}
                content={
                  <div className="pageHeader-popover">
                    <CgProfile size={50} color="grey" cursor={"pointer"} />
                    <div className="pageHeader-popTxt1">Anas.786</div>
                    <div className="pageHeader-popTxt2">
                      Anas@bairuhatech.com
                    </div>
                    <Button style={{ width: "100%", marginTop: 5 }}>
                      Logout
                    </Button>
                  </div>
                }
              >
                <CgProfile size={30} color="grey" cursor={"pointer"} />
              </Popover>
            </div> */}
          </Col>
          {props.title === "Users" ? (
            <Col sm={12} xs={12} md={12}>
              <Space.Compact
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Input.Search
                  enterButton
                  loading={props.searchloading}
                  allowClear
                  placeholder="Search User"
                  style={{ width: "30%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      props.onClear();
                    }
                  }}
                  onSearch={(e) => {
                    if (e === "") {
                      props.onClear();
                    } else {
                      console.log(e);
                      handleSearch(e);
                    }
                  }}
                />
              </Space.Compact>
            </Col>
          ) : null}
          {props.title === "News" ? (
            <Col sm={12} xs={12} md={12}>
              <Space.Compact
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Input.Search
                  enterButton
                  loading={props.searching}
                  allowClear
                  placeholder="Search News"
                  style={{ width: "30%" }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      props.onClear();
                    }
                  }}
                  onSearch={(e) => {
                    if (e === "") {
                      props.onClear();
                    } else {
                      console.log(e);
                      handleSearch(e);
                    }
                  }}
                  // onChange={(e) =>
                  //   setSearchValue(e.target.value);
                  // }
                />
                {/* <Button type="primary" onClick={handleSearch}>
                  <BiSearchAlt2 size={20} />
                </Button> */}
              </Space.Compact>
            </Col>
          ) : null}
        </Row>
      </div>
    </>
  );
}

export default PageHeader;
