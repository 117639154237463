import React from "react";
import { IconType } from "react-icons";
import { CgUnavailable } from "react-icons/cg";
import { SlHome } from "react-icons/sl";
import { RxSpeakerLoud } from "react-icons/rx";
import { BiMoviePlay, BiNews, BiLogOutCircle } from "react-icons/bi";
import {
  MdArticle,
  MdLiveTv,
  MdOutlinePoll,
  MdWebStories,
  MdOutlineQuiz,
} from "react-icons/md";
import { IoFootballOutline, IoSchoolOutline } from "react-icons/io5";
import { AiOutlinePaperClip } from "react-icons/ai";
import { RiLogoutCircleRLine, RiAdvertisementLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { BiCategoryAlt } from "react-icons/bi";
import { BsBook } from "react-icons/bs";
import { MdAmpStories } from "react-icons/md";
import { FaRegImages } from "react-icons/fa";
import { CgProfile, CgScreenWide } from "react-icons/cg";
import { TbLayersSubtract } from "react-icons/tb";

function DynamicIcon(props: any) {
  type IconName =
    | "IoFootballOutline"
    | "BiMoviePlay"
    | "CgUnavailable"
    | "SlHome"
    | "RxSpeakerLoud"
    | "MdLiveTv"
    | "RiLogoutCircleRLine"
    | "FiUsers"
    | "BiCategoryAlt"
    | "BsBook"
    | "MdOutlinePoll"
    | "BiNews"
    | "AiOutlinePaperClip"
    | "RiAdvertisementLine"
    | "RiLogoutCircleRLine"
    | "MdAmpStories"
    | "IoSchoolOutline"
    | "MdOutlineQuiz"
    | "FaRegImages"
    | "BiLogOutCircle"
    | "CgProfile"
    | "MdArticle"
    | "MdWebStories"
    | "TbLayersSubtract"
    | "CgScreenWide";

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }

  function Icon({ iconName, size = 26, color = "red" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      IoFootballOutline: IoFootballOutline,
      CgUnavailable: CgUnavailable,
      SlHome: SlHome,
      BsBook: BsBook,
      MdOutlinePoll: MdOutlinePoll,
      RxSpeakerLoud: RxSpeakerLoud,
      BiMoviePlay: BiMoviePlay,
      MdLiveTv: MdLiveTv,
      RiLogoutCircleRLine: RiLogoutCircleRLine,
      FiUsers: FiUsers,
      BiCategoryAlt: BiCategoryAlt,
      BiNews: BiNews,
      RiAdvertisementLine: RiAdvertisementLine,
      AiOutlinePaperClip: AiOutlinePaperClip,
      MdAmpStories: MdAmpStories,
      IoSchoolOutline: IoSchoolOutline,
      FaRegImages: FaRegImages,
      CgProfile: CgProfile,
      BiLogOutCircle: BiLogOutCircle,
      MdArticle: MdArticle,
      TbLayersSubtract: TbLayersSubtract,
      MdWebStories: MdWebStories,
      MdOutlineQuiz: MdOutlineQuiz,
      CgScreenWide: CgScreenWide,
    };

    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable"; // set default icon name
    }

    const IconComponent = icons[iconName];

    return <IconComponent size={size} color={props.color} />;
  }

  return <Icon iconName={props.name} size={props.size} />;
}
export default DynamicIcon;
