import { useEffect, useState } from "react";
import PageHeader from "../../component/pageHeaders";
import TagDataTable from "./component/dataTable";
import { API } from "../../config/API";
import Loadingbox from "../../component/loadingbox";
import axios from "axios";
import "./style.css";
import TagForm from "./component/tagForm";
import { CloseCircleOutlined } from "@ant-design/icons";

import { Button, DatePicker, Input, Tag } from "antd";
import moment from "moment";

function TagScreen(props: any) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState<any>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [item, setItem] = useState({});
  const [method, setMethod] = useState("post");
  const [indicator, setIndicator] = useState<boolean>(true);
  const [filter, setFilter] = useState({ start: 0, limit: pageSize }) as any;

  useEffect(() => {
    loadData(filter);
  }, []);

  const loadData = (filter: any) => {
    let url = API.BASE_URL + API.GET_TAGFILTER;
    axios.post(url, filter).then((response) => {
      let data = response.data.data;
      let meta = response.data.totalCount;
      setData(data);
      setMeta(meta);
      setIsLoading(false);
      setIndicator(false);
    });
  };

  const onPageChange = (page: any, pageSize: any) => {
    window.scrollTo(0, 0);
    let take = page === 1 ? 0 : page * 10 - 10;
    setPage(page);
    setPageSize(pageSize);
    let filters = {
      ...filter,
      start: take,
      limit: pageSize,
    };
    loadData(filters);
  };

  const onEdit = (item: any) => {
    setItem(item);
    setMethod("put");
    setShow(true);
  };
  const Search = async (searchText: any) => {
    if (searchText.length > 2) {
      let filters = {
        ...filter,
        search: searchText,
      };
      setFilter(filters);
      setTimeout(() => {
        loadData(filters);
      }, 500);
    }
  };
  const onChangeDate = (date: any, datestring: any) => {
    if (date) {
      let filters = {
        ...filter,
        createdAt: date,
      };
      setFilter(filters);
      loadData(filters);
    }
  };
  const clearFilters = (key: any) => {
    const updatedFilter = { ...filter };
    delete updatedFilter[key];
    setFilter(updatedFilter);
    loadData(updatedFilter);
  };

  return (
    <div>
      <PageHeader title={"Tags"} length={meta}>
        <div className="homescreen-pageItem">
          <div style={{ width: 250 }}>
            <Input
              allowClear
              placeholder="Search Tag "
              onChange={(e: any) => Search(e.target.value)}
            />
          </div>
          &nbsp;
          <Button
            type="primary"
            onClick={() => {
              setItem({});
              setMethod("post");
              setShow(true);
            }}
          >
            Add New +
          </Button>
        </div>
      </PageHeader>
      <div style={{ padding: 5 }} />
      <div className="homescreen-pageItem">
        <div
          style={{
            flex: 5,
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(filter)?.length > 2 ? "Filter By :" : "All Tags"} &nbsp;
          {Object.keys(filter)?.map(
            (key, index) =>
              key !== "start" &&
              key !== "limit" && (
                <Tag style={{ marginBottom: 5 }}>
                  {`${key}:  ${
                    key === "createdAt"
                      ? moment(filter[key]).format("DD-MM-YYYY")
                      : filter[key]
                  }`}{" "}
                  <CloseCircleOutlined onClick={() => clearFilters(key)} />
                </Tag>
              )
          )}
        </div>
        <div style={{ flex: 1 }}>
          <DatePicker
            allowClear
            style={{ width: 200 }}
            onChange={onChangeDate}
          />
        </div>
        &nbsp;
      </div>
      {isLoading ? (
        <Loadingbox />
      ) : (
        <TagDataTable
          data={data}
          meta={meta}
          onClear={() => {
            loadData(filter);
          }}
          page={page}
          pagesize={pageSize}
          onEdit={(val: any) => onEdit(val)}
          onPageChange={onPageChange}
          refresh={() => loadData(filter)}
          Search={(val: any) => Search(val)}
        />
      )}
      {show ? (
        <TagForm
          item={item}
          open={show}
          method={method}
          onCancel={() => setShow(false)}
          onSuccess={() => loadData(filter)}
        />
      ) : null}
    </div>
  );
}

export default TagScreen;
