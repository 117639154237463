import "./styles.css";
import PageHeader from "../../component/pageHeaders";
import Home from "./screens/home";
import Details from "./screens/details";
import { Tabs, TabsProps } from "antd";
import Editorial from "./screens/editorial";
import EditorialDetails from "./screens/editorialDetailed";
import Articles from "./screens/articles";
import ArticlesDetailed from "./screens/articleDetailed";
import Njayar from "./screens/njayar";
import NjayarDetailed from "./screens/njayarDetailed";
import Vidya from "./screens/vidya";
import VidyaDetailed from "./screens/vidyaDetailed";
import Search from "./screens/searchScreen";
import Podcast from "./screens/podcast";

function AddScreen() {

  const onChange = (key: string) => {
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Home Screen",
      children: <Home />,
    },
    {
      key: "2",
      label: "Details Screen",
      children: <Details />,
    },
    {
      key: "3",
      label: "Editorial Screen",
      children: <Editorial />,
    },
    {
      key: "4",
      label: "Editorial Detaild",
      children: <EditorialDetails />,
    },
    {
      key: "5",
      label: "Todays Article",
      children: <Articles />,
    },
    {
      key: "6",
      label: "Todays Article Detaild",
      children: <ArticlesDetailed />,
    },
    {
      key: "7",
      label: "Vidya Prabhaatham Screen",
      children: <Vidya />,
    },
    {
      key: "8",
      label: "Vidya Prabhaatham Detaild",
      children: <VidyaDetailed />,
    },
    {
      key: "9",
      label: "Njayar Prabhaatham Screen",
      children: <Njayar />,
    },
    {
      key: "10",
      label: "Njayar Prabhaatham Detaild",
      children: <NjayarDetailed />,
    },
    {
      key: "11",
      label: "Search Screen",
      children: <Search />,
    },
    {
      key: "12",
      label: "Podcast Screen",
      children: <Podcast />,
    },
  ];

  return (
    <div className="AddScreen">
      <PageHeader title={"Manage Adds"} />
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{ paddingLeft: 10, paddingRight: 10 }}
        items={items}
        onChange={onChange}
      />
      <br />
      <br />
    </div>
  );
}

export default AddScreen;
