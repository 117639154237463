export const API = {
  BASE_URL: "https://news-server.bairuhatech.com/gold-news/api/",
  // BASE_URL: "http://localhost:8022/api/",
  GRAPHQL: "https://news-server.bairuhatech.com/gold-news/graphql",
  // GRAPHQL: "http://localhost:8080/graphql",
  APP_URL: "https://www.goldnews.com/",

  // ---------endpoints----- --
  // LOGIN: "auth/local",
  LOGIN: "userLogin",
  IMAGE_UPLODER: "s3upload",
  UPLOADTO_MEDIALIBRARY: "insertMediaLibrary",
  VERIFY_OTP: "verifyOTP",
  REGISTER: "auth/local/register",
  CREATE_USER: "createUser",
  GET_ROLE: "users/me?populate=*",
  ALL_NEWS: "all-news",
  GET_NEWS: "filterNews",
  GET_USERS_LIST: "filterUsers",
  PUBLISH_NEWS: "publish",
  EDITORIALS: "editorials",
  GET_EDITORIALS: "EditorialFilter",
  USERS: "users",
  CREATENEWS: "createNews",
  CATEGORY: "categories",
  GET_CATEGORY: "CategoryFilter",
  GET_TAGFILTER: "TagFilter",
  USER_ROLE: "users-permissions/roles",
  TAGS: "tags",
  PODCAST: "podcasts",
  REELS: "reels",

  POLLS: "polls",
  POLL_OPTION: "poll-options",
  CREATE_POLL: "createPolls",
  EDIT_POLL: "editPolls/",

  MAKE_VISIBLE: "polls/makeVisible",
  BANNERS: "bannners",
  GET_BANNERS: "filterBanner",
  GET_NJYAR: "NJayarFilter",
  GET_VIDYA: "VidyaFilter",
  WEB_STORIES: "webstories",
  ADD_TO_TRASH: "trash",
  UNTRASH: "unTrash",
  VIDYA_PRABHAADHAM: "vidyaprabhaadhams",
  NJAYAR_PRABHAADHAM: "njayarprabhaadhams",
  INSNAP: "in-snaps",
  GET_TRASHED: "getTrashedItems",
  PUT_UPDATE_USER: "updateUser/",
  GET_USERS: "getusers",
  GET_USERSEARCH: "usersearch",
  DELETE_USER: "deleteUser/",
  SUBCATEGORY: "subcategories",
  GET_TAGS: "getTags",
  GET_ALL_LIBRARIES: "getAllLibraries",
  GET_ALL_SEARCHLIBRARY: "getAllSearchLibraries",
  GET_ALLNEWS_ID: "getAllNewsById/",
  ARTICLE: "articles",
  GET_ARTICLE: "ArticleFilter",
  HIGHLIGHTS: "highlights",
  UPDATE_NEWS: "updateNews",
  NJAYAR_MASTER: "njayarprabhaadham-masters",
  VIDYA_MASTER: "vidyaprabhaadham-masters",
  GET_INSNAP: "insnapFilter",
  GET_WEBSTRY: "WebStoriesFilter",
  GET_ADD: "advertisements",

  EPAPER_LIST: "filterEpaper", //POST
  EPAPER_ADD: "e-papers", // POST
  EPAPER_EDIT: "e-papers/", // PUT
  EPAPER_DELETE: "e-papers/", // DELETE
  EPAPER_PUBLISH: "e-papers", //PUT
  EPAPER_DETAILS: "e-papers/", //GET

  QUIZZ_MASTER_LIST:"filterQuiz",
  QUIZZ_MASTER_ADD:"quiz-masters",
  QUIZZ_MASTER_EDIT:"quiz-masters/",//PUT
  QUIZZ_MASTER_DELETE:"quiz-masters/", // DELETE
  QUIZZ_MASTER_PUBLISH: "quiz-masters", //PUT
  QUIZZ_MASTER_DETAILS:"quiz-masters/", //GET

  QUIZZ_ITEM_LIST:"filterQuizItems",
  QUIZZ_ITEM_ADD:"addQuiz",
  QUIZZ_ITEM_EDIT:"editQuiz",
  QUIZZ_ITEM_DELETE:"quiz-items/",
  QUIZZ_ITEM_DETAILS:"quiz-items/",
  QUIZZ_ITEM_ACTIVATE:"quiz-item/activate-quiz/",

  QUIZZ_ANSWER_LIST:"filterQuizAnswers",

  QUIZZ_WINNER_LIST:"",
  QUIZZ_WINNER_ADD:"",
  QUIZZ_WINNER_EDIT:"",
  QUIZZ_WINNER_DELETE:"",
  QUIZZ_WINNER_DETAILS:"",

  ADVERTISEMENT_ADD:"advertisements",
  ADVERTISEMENT_EDIT:"advertisements/",
  ADVERTISEMENT_DELETE:"advertisements/",
  ADVERTISEMENT_LIST:"advertisements/filter"
};
