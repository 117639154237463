import { useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  message,
  notification,
} from "antd";
import { Row, Col, Container } from "react-bootstrap";
import Logo from "../../assets/image/logo.png";
import "./styles.css";
import { POST } from "../../utils/apirequest";
import { API } from "../../config/API";
import Lockmodal from "../../component/lock/index";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/userslice";

const LoginScreen = (props: any) => {
  const dispatch = useDispatch();
  const navigate: any = useNavigate();
  const [loading, setLoading] = useState(false);
  const [lockmodal, setlockModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  let userDesc = localStorage.getItem("userlist");
  let userlistData: any = [];

  if (userDesc !== null) {
    let user = JSON.parse(userDesc);
    userlistData = user;
  }

  let uniqueUserlistData = userlistData?.filter(
    (value: any, index: any, self: any) => {
      return (
        index === self.findIndex((obj: { id: any }) => obj.id === value.id)
      );
    }
  );

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let body = {
        email: values.email,
        password: values.password,
      };
      let url = API.LOGIN;
      const response: any = await POST(url, body);
      if (response.status) {
        if (response.blocked === true) {
          setlockModal(true);
          setErrorMessage(
            `Your account login has been blocked. Please find an alternative way to access it.`
          );
        }
        if (response.status === 200) {
          dispatch(login(response));
          notification["success"]({
            message: "success",
            description: "Login Success",
          });
          navigate("/Auth");
        } else {
          notification["error"]({
            message: "error",
            description: response.message,
          });
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong......!");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="loginSection">
      <Container>
        <Row>
          <Col sm={4} md={4}></Col>
          <Col sm={4} md={4} xs={12}>
            <div className="loginSection-box">
              <div className="loginSection-logobox">
                <img src={Logo} className="loginSection-logo" />
              </div>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div>E-mail</div>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  {userlistData.length ? (
                    <Select style={{ width: "100%" }} mode="tags" size="large">
                      {uniqueUserlistData.length > 0 &&
                        uniqueUserlistData.map((item: any, index: any) => {
                          return (
                            <Select.Option key={index} value={item?.email}>
                              {item?.email}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  ) : (
                    <Input style={{ width: "100%" }} size="large" />
                  )}
                </Form.Item>
                <div>Password</div>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      message: "Please input your password!",
                      required: true,
                    },
                  ]}
                >
                  <Input.Password style={{ width: "100%" }} size="large" />
                </Form.Item>
                <Form.Item name={"remember"}>
                  <Checkbox onChange={() => console.log("Checkbox")}>
                    <div>Remember Me</div>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    size="large"
                    block
                    style={{ height: 50 }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col sm={4} md={4}></Col>
        </Row>
      </Container>
      {lockmodal ? (
        <Lockmodal
          visible={lockmodal}
          close={() => setlockModal(false)}
          data={errorMessage}
        />
      ) : null}
    </div>
  );
};

export default LoginScreen;
