"use client";
import { Col, Container, Row } from "react-bootstrap";
import { GrHomeRounded } from "react-icons/gr";
import { TfiMoreAlt } from "react-icons/tfi";
import { Popover, Select } from "antd";
import { useState } from "react";
import "../styles.css";

export default function Category(props: any) {
  const Categories = [
    "News ",
    "Gulf-News ",
    "Sci-Tech ",
    "Career ",
    "Sports ",
    "Business ",
    "Podcasts ",
    "Editorial ",
    "Info ",
    "EDucation ",
  ];

  return (
    <div className="">
      <div className="SubHeader_box">
        <Container fluid={false} className="SubHeader_Container">
          <Row>
            <Col sm={1} xs={12} md={1} lg={1} xl={1}>
              <div className="SubHeader_box2">
                <GrHomeRounded size={18} />
              </div>
            </Col>
            <Col sm={11} xs={12} md={11} lg={11} xl={11}>
              <div className="SubHeader_box2">
                {Categories?.map((cat: any, index: number) => {
                  return (
                    <div key={index} className="SubHeader_txt1">
                      {cat}
                    </div>
                  );
                })}

                <div className="SubHeader_txt1">
                  <TfiMoreAlt size={24} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
