import { useState } from "react";
import { Space, Table, Tag, Popconfirm, Switch, Pagination } from "antd";
import type { ColumnsType } from "antd/es/table";
import { IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";

function Datatable(props: any) {
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const navigate = useNavigate();

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };
  const handleSwitchChange = (checked: any, id: any) => {
    props.onUpdate(id, checked === false ? true : false);
  };

  const onEdit = (item: any) => {
    navigate("/Auth/updateuser/form", {
      state: {
        item,
        head: "Update User",
      },
    });
  };
  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 500,
      sorter: (a: any, b: any) => a.username.localeCompare(b.username),
      sortOrder: sortedInfo.columnKey === "username" && sortedInfo.order,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "10px" }} onClick={() => onEdit(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 500,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "10px" }} onClick={() => onEdit(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "role",
      width: 500,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "10px" }} onClick={() => onEdit(record)}>
            {record?.role?.name}
          </div>
        );
      },
    },
    {
      title: "Posts",
      dataIndex: "all_news",
      key: "all_news",
      width: 500,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "10px" }}>{record?.all_news?.count}</div>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      width: 200,
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record?.blocked ? "red" : "green"}
              style={{ fontSize: "10px" }}
            >
              {record?.blocked ? "Inactive" : "Active"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "",
      key: "publishedAt",
      width: 70,
      render: (text, record: any) => {
        return (
          <Switch
            defaultChecked={record?.blocked === false ? true : false}
            onChange={(val) => handleSwitchChange(val, record?.id)}
          />
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => onEdit(record)}
              />
            </Space>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => props.onDelete(record.id)}
            >
              <Space size="middle">
                <IoTrashOutline size={20} color="red" cursor={"pointer"} />
              </Space>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-2" style={{ height: "65vh", overflowY: "scroll" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        onChange={handleChange}
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props.meta && props.meta}
          pageSize={props?.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default Datatable;
