import {
  Space,
  Table,
  Tag,
  Popconfirm,
  Pagination,
  Tooltip,
  notification,
  Button,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { IoCopyOutline, IoTrashOutline } from "react-icons/io5";
import axios from "axios";
import { API } from "../../../config/API";
import { useSelector } from "react-redux";
import moment from "moment";
import { FaRegEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { LuUndo2 } from "react-icons/lu";
import { TbSquareNumber1, TbSquareNumber2 } from "react-icons/tb";

function DataTable(props: any) {
  const User = useSelector((state: any) => state.User.user);
  const [Notifications, contextHolder] = notification.useNotification();

  const copyLink = (record: any) => {
    let link = record?.permalink?.replace(/\s+/g, "-");
    let url = `${record?.head} \nRead more at: ${API.APP_URL}details/${record.id}?link=${link}`;
    navigator.clipboard.writeText(url).then(() => {
      message.success("link copied");
    });
  };

  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Photos",
      key: "imageurl",
      dataIndex: "imageurl",
      render: (text, record: any) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => props.onEdit(record)}
          >
            {record.image ? (
              <img
                key={record.image}
                src={record.image}
                alt="Table Image"
                style={{ width: 70, borderRadius: 6 }}
              />
            ) : (
              <div className="homescreen-table-NoImg">No media</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Headline",
      dataIndex: "newshead",
      key: "newshead",
      width: 600,
      render: (text, record: any) => {
        return (
          <>
            {record?.onlive === true ? <Tag color="red">Live</Tag> : null}
            {/* {record.editing_Status ? (
              <strong
                style={{ color: "#DAA520", fontSize: "12px" }}
              >{`  ${record.liveEditingUser}  is currently editing`}</strong>
            ) : null} */}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => props.onEdit(record)}
            >
              {record.head}
            </div>
          </>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "username",
      key: "username",
      width: 300,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => props.onData(record?.user?.id)}
          >
            {record?.user?.username}
          </div>
        );
      },
    },
    {
      title: "Date-Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (text, record: any) => {
        return (
          <div
            style={{ cursor: "pointer", fontSize: 12 }}
            onClick={() => props.onEdit(record)}
          >
            {moment(record?.createdAt).format("DD-MM-YYYY-h:mm a")}
          </div>
        );
      },
    },
    {
      title: "type",
      dataIndex: "newshead",
      key: "address",
      width: 200,
      render: (text, record: any) => {
        return (
          <div
            style={{ fontSize: "10px", cursor: "pointer" }}
            onClick={() => props.onEdit(record)}
          >
            {record?.categories?.map((item: any) => {
              return <Tag style={{ fontSize: 10 }}>{item?.category}</Tag>;
            })}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "isHighPriority",
      key: "isHighPriority",
      render: (text, record: any) => {
        return (
          <>
            {record.isHighPriority ? <TbSquareNumber1 size={15} /> : null}
            {record.isSecondLeads ? <TbSquareNumber2 size={15} /> : null}
          </>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={
                record?.is_deleted === true
                  ? "red"
                  : record?.publishedAt
                  ? "green"
                  : record?.publishedAt === null && record?.publish_at
                  ? "pink"
                  : "blue"
              }
              style={{ fontSize: "10px" }}
            >
              {record?.is_deleted === true
                ? "trash"
                : record?.publishedAt
                ? "Published"
                : record?.publishedAt === null && record?.publish_at
                ? "Scheduled"
                : "Draft"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "Log",
      key: "log",
      render: (text, record: any) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <FaRegEye size={20} onClick={() => props.onlog(record.id)} />
          </div>
        );
      },
    },
    {
      title: "Task",
      key: "action",
      render: (_, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            {record.publishedAt && (
              <Space size="middle" style={{ marginRight: 10 }}>
                <IoCopyOutline
                  size={20}
                  color="green"
                  cursor={"pointer"}
                  onClick={() => copyLink(record)}
                />
              </Space>
            )}
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => props.onEdit(record)}
              />
            </Space>
            &nbsp;
            <Space size="middle" style={{ marginRight: 10 }}>
              <Tooltip
                title={`${
                  record.is_deleted === true ? "Recover" : "Move"
                } From Trash`}
                color={"black"}
              >
                {record.is_deleted === true ? (
                  <LuUndo2
                    color="blue"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.unTrash(record)}
                  />
                ) : (
                  <IoTrashOutline
                    color="red"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => props.onTrash(record)}
                  />
                )}
              </Tooltip>
            </Space>
            {record.is_deleted === true && User.role.id === 4 ? (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDeleteItem(record.id)}
              >
                <Button size="small" danger style={{ fontSize: 12 }}>
                  Remove
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  const onDeleteItem = (id: any) => {
    let url = API.BASE_URL + API.ALL_NEWS + `/${id}`;
    axios
      .delete(url)
      .then((response) => {
        Notifications["success"]({
          message: `Successfully deleted`,
        });
        props.refresh();
      })
      .catch((err) => {
        console.log(err);
        Notifications["error"]({
          message: `Oops! Something went wrong`,
        });
      });
  };

  return (
    <div style={{ marginTop: 10 }}>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={props.data}
        className="commen-table-custom-scrollbar"
        pagination={false}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props.meta && props.meta}
          pageSize={props?.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default DataTable;
