import React, { useEffect } from "react";
import PageHeader from "../../../component/pageheader";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "antd";

function CategoryForm() {
  const navigate = useNavigate();

  const onFinish = (values: any) => {};
  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 14 },
  };
  return (
    <Form layout="vertical" name="nest-messages" onFinish={onFinish}>
      <PageHeader
        title={"Create Category"}
        buttontext={"Submit"}
        goBack={() => navigate("/Auth/categories")}
        onBtnPress={() => onFinish}
      />
      <div style={{ marginTop: 30 }}>
        <Form.Item label="Username" name="username" {...formItemLayout}>
          <Input />
        </Form.Item>
      </div>
    </Form>
  );
}

export default CategoryForm;
