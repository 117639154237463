import { BsFillPlusCircleFill } from "react-icons/bs";
import { LuMinus } from "react-icons/lu";

function ImageItem(props: any) {
  return (
    <div className="NewsImageBox">
      {props?.imageurl === "" ? (
        <div onClick={() => props.upload()} style={{ textAlign: "center" }}>
          <BsFillPlusCircleFill color="#c2c2c2" size={40} />
          <br />
          <div className="imageUplaod-Txt">Click to Upload</div>
        </div>
      ) : (
        <>
          <LuMinus
            className="image-delete-btn"
            size={25}
            onClick={() => props?.delete()}
          />
          <img
            src={props?.imageurl}
            alt="img"
            className="NewsImageBox-img"
            onClick={() => props.upload()}
          />
        </>
      )}
    </div>
  );
}

export default ImageItem;
