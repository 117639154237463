import { SiGoogleanalytics } from "react-icons/si";
import { MdPublishedWithChanges } from "react-icons/md";
import { useState } from "react";
import NewsLog from "../../../component/newsLog";

function Analytics(props: any) {
  const [log, setLog] = useState(false);
  return (
    <>
      <div className="Analytics-box1">
        <div className="newsFormLabels" style={{ marginBottom: 0 }}>
          Post Impressions
        </div>
        <SiGoogleanalytics />
      </div>
      <hr />
      <div className="Analytics-box1" style={{ marginBottom: 10 }}>
        <span>Views</span>
        <span>
          {props.data && props?.data.View ? `${props?.data.View} + ` : "0"}
        </span>
      </div>
      <div className="Analytics-box1">
        <span>Share</span>
        <span>{props.data && props?.data.shares ? `${props?.data.shares} + ` : "0"}</span>
      </div>
      <br />
      <div className="Analytics-box1" onClick={() => setLog(true)} style={{cursor:"pointer"}}>
        <div className="newsFormLabels" style={{ marginBottom: 0 }}>
          Revisions (
          {props.data && props?.data.logs.count ? props?.data.logs.count : "0"})
        </div>
        <MdPublishedWithChanges size={18} />
      </div>
      <hr />
      {log ? (
        <NewsLog
          visible={log}
          id={props?.data?.id}
          close={() => setLog(false)}
        />
      ) : null}
    </>
  );
}

export default Analytics;
