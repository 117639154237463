import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  notification,
  message,
  Switch,
  Tag,
} from "antd";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Editor as TextEditor } from "@tinymce/tinymce-react";
import moment from "moment";
import Loadingbox from "../../../component/loadingbox";
import ImageUploader from "../../../component/image-uploader/index";
import { useParams, useLocation } from "react-router-dom";
import PageHeader from "../../../component/pageHeaders";
import TextArea from "antd/es/input/TextArea";
import "../styles.css";
import { uploadImageToS3 } from "../../../config/s3config/s3fileUpload";
import MediaLibrary from "../../../component/image-uploader/mediaLibrary";
import ImagePicker from "../../../component/image-picker";
import SocialShare from "./socialShareView";
import {
  PopulateSubCategory,
  getInitialValues,
  PreviewData,
} from "../utils/formactions";
import {
  CreateNews,
  LoadCategories,
  LoadSubCategories,
  LoadTags,
  getNewsById,
} from "../utils/apiRequest";
import { API } from "../../../config/API";
import MediaJoin from "../../../component/joinSocialMedia";
import axios from "axios";
import DataUploadIMage from "../../../component/uploadeImageDetails";
import { Container, Col, Row } from "react-bootstrap";
import ImageItem from "./imageItem";
import PreviewModal from "../../../component/previewSession/preview";
import { IoCopyOutline } from "react-icons/io5";
import Analytics from "./analytics";

function NewForm(props: any) {
  const User = useSelector((state: any) => state.User.user);
  const location = useLocation();
  let { id } = useParams();
  const initialized = useRef(false);
  const editing = useRef(false);
  const DraftData = useRef<any>({});
  const DraftImage = useRef<any>({});
  const [form] = Form.useForm();
  const [data, setData] = useState<any>();
  const [content, setContent] = useState<any>();
  const [blurb, setBlurb] = useState<any>();
  const [categories, setCategories] = useState([]);
  const [subcategoryData, setSubcategoryData] = useState([]) as any;
  const [subcategory, setSubcategory] = useState([]) as any;
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [loading2, setLoading2] = useState(true);
  const [microphone, setMicrophone] = useState(false);
  const [show, setShow] = useState(false);
  const [returnedNewsId, setReturnedNewsId] = useState() as any;
  const [permission, setPermission] = useState() as any;
  const [imageupload, setImageupload] = useState(false);
  const [imageuploaddata, setImageuploaddata] = useState<any>({
    imageurl1: "",
    imageurl2: "",
    imageurl3: "",
  });
  const [audio, setAudio] = useState(false);
  const [audiouploader, setAudiouploader] = useState<any>();
  const [isChecked, setIsChecked] = useState<any>();
  const [authorImg, setAuthorImg] = useState("");
  const [medialibrarymodal, setMedialibrarymodal] = useState(false);
  const [uploadImg, setUploadImg] = useState(false);
  const [UpdateuploadImgData, setUpdateUploadImgData] = useState(false);
  const [imageType, setImageType] = useState("");
  const [imageName, setImageName] = useState("");
  const [PreviewDatas, setPreviewDatas] = useState<any>();
  const [imageBase64, setImageBase64] = useState("");
  const [button, stepButton] = useState(false);

  const [socialshareModal, setSocialshareModal] = useState(false) as any;

  useEffect(() => {
    if (id !== undefined) {
      LoadDAta(id);
    }
    loadCategories();
    loadSubCategories();
    loadTags();
  }, []);

  const handleUrlChange = () => {
    if (!initialized.current) {
      initialized.current = true;
      if (id) {
        editStart(data, false, User?.username);
      }
      if (editing.current === true) {
        if (id === undefined) {
          saveDraft();
        }
      }
    }
  };

  window.addEventListener("popstate", handleUrlChange);

  window.addEventListener("beforeunload", (event) => {
    console.log("beforeunload = = = = >")
  });

  const LoadDAta = async (id: any) => {
    let url = API.BASE_URL + API.GET_ALLNEWS_ID + `${id}`;
    try {
      const response: any = await getNewsById(url);
      if (response) {
        setData(response?.data);
        setContent(response?.data?.body);
        setBlurb(response?.data?.title);
        let obj = {
          imageurl1: response?.data?.image ? response?.data?.image : "",
          imageurl2: response?.data?.image2 ? response.data.image2 : "",
          imageurl3: response?.data?.social_image
            ? response.data.social_image
            : "",
        };
        setImageuploaddata(obj);
        DraftImage.current = obj;
        let initails: any = getInitialValues(response.data);
        form.setFieldsValue(initails);
        setIsChecked(
          response?.data?.isHighPriority === true
            ? 1
            : response?.data?.isSecondLeads === true
            ? 2
            : 3
        );
        setPermission(response?.data?.id);
        editStart(response?.data, true, User?.username);
      } else {
        console.log("Error fetching data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const editStart = (val: any, editflag: Boolean, EditUser: any) => {
    try {
      let url = API.BASE_URL + API.ALL_NEWS + `/${id}/`;
      let body = {
        data: {
          editing_Status: editflag,
          liveEditingUser: EditUser,
        },
      };
      axios.put(url, body).then((response) => response);
    } catch (error) {
      console.log(error);
    }
  };

  const addImage = (image: any, caption: any) => {
    if (window.tinymce && window.tinymce.activeEditor) {
      const editor = window.tinymce.activeEditor;
      let Moment = `${moment().format(
        "YYYY-MM-DDHH:MM:SS"
      )}.suprabhaatham-news.png`;
      let alt = caption ? caption : Moment;
      editor.insertContent(`<img src="${image}" alt="${alt}" />`);
      setMedialibrarymodal(false);
    }
  };

  const addButtonMedia = (buttonContent: any) => {
    let color = "";
    if (buttonContent.media === "Whatsapp") {
      color = "#00e676";
    } else if (buttonContent.media === "Telegram") {
      color = "#64b5f6";
    } else {
      color = "#f50057";
    }
    const styles = `color: white; font-size: 16px;background:${color}; border:none; border-radius:25px;height:50px;text-align: center; padding-left:20px; padding-right:20px`;
    if (window.tinymce && window.tinymce.activeEditor) {
      const editor = window.tinymce.activeEditor;
      editor.insertContent(
        `<a  href="${buttonContent.Link}"><button style="${styles}" >${buttonContent?.Text}</button></a>`
      );
      stepButton(false);
    }
  };

  const loadCategories = async () => {
    const res: any = await LoadCategories();
    setCategories(res.data);
  };

  const loadSubCategories = async () => {
    const res: any = await LoadSubCategories();
    setSubcategoryData(res.data);
    setSubcategory(res.data);
  };

  const loadTags = async () => {
    const res: any = await LoadTags();
    setTags(res);
  };

  const getImageUrl = (urls: any) => {
    setImageuploaddata(urls);
    DraftImage.current = urls;
  };

  const { imageurl1, imageurl2, imageurl3 } = imageuploaddata;
  let pic1 = imageurl1;
  let pic2 = imageurl2;
  let pic3 = imageurl3;

  const handleEditorChange = (content: any) => {
    setContent(content);
    editing.current = true;
  };

  const addImagesToList = (data: any) => {
    try {
      let DataObj = imageuploaddata;
      if (data.type === "image1") {
        DataObj["imageurl1"] = "";
        DataObj["imageurl1"] = data.image_url;
        DataObj["image1Value"] = "";
        DataObj["image1Value"] = data.image_desc;
        DataObj["image1Caption"] = "";
        DataObj["image1Caption"] = data.image_Caption;
      } else if (data.type === "image2") {
        DataObj["imageurl2"] = "";
        DataObj["imageurl2"] = data.image_url;
        DataObj["image2Value"] = "";
        DataObj["image2Value"] = data.image_desc;
        DataObj["image2Caption"] = "";
        DataObj["image2Caption"] = data.image_Caption;
      } else if (data.type === "image3") {
        DataObj["imageurl3"] = "";
        DataObj["imageurl3"] = data.image_url;
        DataObj["image3Value"] = "";
        DataObj["image3Value"] = data.image_desc;
        DataObj["image3Caption"] = "";
        DataObj["image3Caption"] = data.image_Caption;
      } else if (data.type === "authorImg") {
        setAuthorImg(data.image_url);
      }
      setImageuploaddata(DataObj);
      DraftImage.current = DataObj;
      setSocialshareModal(null);
    } catch (err) {
      console.log(err);
    }
  };

  function deleteImagesFromList(key: any) {
    try {
      setImageuploaddata((prevImages: any) => {
        const updatedImages = { ...prevImages };
        updatedImages[key] = "";
        return updatedImages;
      });
      notification.success({
        message: `Image Removed`,
      });
    } catch (err) {
      notification.error({
        message: `Failed to remove`,
      });
    }
  }

  const handleblurbEditorChange = (blurb: any, editor: any) => {
    setBlurb(blurb);
    editing.current = true;
  };

  const handleCategoriesChange = (val: any) => {
    const subCat = PopulateSubCategory(val, subcategoryData);
    setSubcategory(subCat);
  };

  const handleUrlCopy = (type: any) => {
    const perma = form.getFieldValue("permalink");
    let formattedPerma = perma?.replace(/\s+/g, "-");
    const link = `${API.APP_URL}details/${
      returnedNewsId ? returnedNewsId : data?.id
    }?${formattedPerma}`;
    if (type === "copy") {
      navigator.clipboard.writeText(link).then(() => {
        message.success("link copied");
      });
    } else if (type === "preview") {
      window.open(link, "_blank");
    }
  };

  const onFinish = async (values: any) => {
    try {
      await form.validateFields();
      setLoading(true);
      setType(values.type);
      let body = {
        id: data?.id || returnedNewsId,
        blurb: blurb,
        body: content,
        image: pic1,
        image2: pic2,
        onlive: data?.onlive,
        social_image: pic3,
        publishedAt:
          values.type === "publish" ? new Date() : data?.publishedAt || null,
        audio: audiouploader ? audiouploader : null,
        user: id !== undefined ? data?.user?.id : User?.id,
        image1_description: imageuploaddata?.image1Value,
        image2_description: imageuploaddata?.image2Value,
        author_image: authorImg ? authorImg : null,
        image1_caption: imageuploaddata?.image1Caption,
      };
      const res: any = await CreateNews(values, body, User?.id);
      if (res.id) {
        editing.current = false;
        setReturnedNewsId(res?.id);
        setTimeout(() => {
          window.location.replace(`/Auth/home/news/${res.id}`);
        }, 200);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to validate:", error);
    }
  };

  const onchangeData = (body: any, allValues: any) => {
    editing.current = true;
    const tagsfilteredArray = allValues?.tags ? allValues.tags.join("|") : "";
    var obj = {
      title: blurb || "",
      head: allValues?.head || "",
      author_image: allValues?.author_image || "",
      author_name: allValues?.author_name || "",
      categories:
        allValues?.categories?.map((item: any) => {
          let cat = JSON.parse(item);
          let id = cat.id;
          return id;
        }) || [],
      user: User?.id,
      isHighPriority: allValues?.isHighPriority || false,
      permalink: allValues?.permalink || "",
      publish_time: allValues?.publish_time || "",
      subcategory:
        allValues?.subcategory?.map((item: any) => {
          let cat = JSON.parse(item);
          return cat;
        }) || [],
      newsTag: tagsfilteredArray,
      body: content,
    };
    if (allValues) {
      DraftData.current = obj;
    }
  };

  const saveDraft = async () => {
    try {
      if (DraftData) {
        let url = API.BASE_URL + API.CREATENEWS;
        let obj = {
          data: {
            author_image: DraftData?.current?.author_image,
            author_name: DraftData?.current?.author_name,
            body: DraftData?.current?.body,
            categories: DraftData?.current?.categories,
            head: DraftData?.current?.head,
            image: DraftImage?.current?.imageurl1,
            image2: DraftImage?.current?.imageurl2,
            isHighPriority: DraftData?.current?.isHighPriority,
            permalink: DraftData?.current?.permalink,
            social_image: DraftImage?.current?.imageurl3,
            Subcategories: DraftData?.current?.subcategory,
            newsTag: DraftData?.current?.newsTag,
            title: DraftData?.current?.blurb,
            user: DraftData?.current?.user,
            image1_description: imageuploaddata?.image1Value,
            image1_caption: imageuploaddata?.image1Caption,
            image2_description: imageuploaddata?.image2Value,
          },
        };
        axios.post(url, obj).then((response) => response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addNew = () => {
    window.location.replace(`/Auth/home/news`);
  };

  const previewData = () => {
    let body = {
      items: data,
      titleValue: blurb,
      body: content,
      tags: tags,
      pic1: pic1,
      imageBase64: imageBase64,
    };
    const Data = PreviewData(form, body);
    setPreviewDatas(Data);
    setShow(true);
  };

  return (
    <div style={{ height: "90vh", overflowY: "scroll" }}>
      <PageHeader
        title={`${id !== null && id !== undefined ? "Edit" : "Create"} News`}
      >
        <div className="homescreen-pageItem">
          {id !== null && id !== undefined ? (
            <Tag
              style={{ padding: 5, paddingLeft: 20, paddingRight: 20 }}
              color={data?.publishedAt ? "green" : "volcano"}
            >
              {data?.publishedAt ? "Published" : " Draft"}
            </Tag>
          ) : null}
          <Button onClick={previewData} disabled={data && !data.id}>
            Preview
          </Button>
          &nbsp;
          {id !== null && id !== undefined ? (
            <div>
              <Button
                onClick={() => handleUrlCopy("preview")}
                disabled={data && !data.id}
              >
                Website Preview
              </Button>
              &nbsp;
              <Button type="primary" onClick={addNew}>
                Create New +
              </Button>
            </div>
          ) : null}
        </div>
      </PageHeader>
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onchangeData}
        name="add-news"
        scrollToFirstError
      >
        <Container fluid>
          <Row>
            <Col sm={9} xs={12}>
              <label className="newsFormLabels">Heading</label>
              <Form.Item name="head">
                <TextArea />
              </Form.Item>
              {loading2 && <Loadingbox />}
              <input
                id="my-file"
                type="file"
                name="my-file"
                style={{ display: "none" }}
              />
              <TextEditor
                apiKey='g1wnxpnbtg8skog7heo7xsww9mc0ib4jj0s49xdixf5d3cmb'
                value={content}
                init={{
                  height: 500,
                  menubar: true,
                  directionality: "ltr",
                  plugins:
                    "advlist autolink lists link image pageembed code preview charmap print preview anchor help searchreplace visualblocks code insertdatetime media table paste wordcount pageembed code preview image code",
                  toolbar:
                    "  | bold italic | \
                    alignleft aligncenter alignright | \
                    bullist numlist outdent indent |subscript superscript |link unlink| help | forecolor backcolor | \
                    \
                    \
                    \
                    | image "
                }}
                onEditorChange={handleEditorChange}
                onInit={() => setLoading2(false)}
              />
              <Row>
                <Col md={6}>
                  <label className="newsFormLabels">Categories</label>
                  <Form.Item
                    name="categories"
                    rules={[
                      {
                        required: true,
                        message: "Please select Categories!",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      mode="multiple"
                      onChange={handleCategoriesChange}
                    >
                      {categories?.length > 0 &&
                        categories?.map((item: any, index: any) => {
                          let obj = {
                            id: item.id,
                            category: item.attributes.category,
                          };
                          let category = JSON.stringify(obj);
                          return (
                            <Select.Option key={index} value={category}>
                              {item?.attributes?.category}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <label className="newsFormLabels">Tags</label>
                  <Form.Item
                    name="tags"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one tag",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      mode="tags"
                      style={{ background: "transparent" }}
                    >
                      {tags.length &&
                        tags.map((item: any, index: any) => {
                          let tag = item.tag;
                          return (
                            <Select.Option key={index} value={tag}>
                              {item.tag}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <label className="newsFormLabels">Sub-Category</label>
                  <Form.Item
                    name="subcategory"
                    rules={[
                      {
                        required: true,
                        message: "Please select subcategory!",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      mode="multiple"
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {subcategory?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.id}>
                            {item.attributes.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <label className="newsFormLabels">Blurb</label>
              <input
                id="my-file"
                type="file"
                name="my-file"
                style={{ display: "none" }}
                onChange={() => {}}
              />
              <TextEditor
               apiKey='g1wnxpnbtg8skog7heo7xsww9mc0ib4jj0s49xdixf5d3cmb'
                value={blurb}
                init={{
                  height: 200,
                  menubar: false,
                  directionality: "ltr",
                  plugins:
                    "advlist autolink lists link image pageembed code preview charmap print preview anchor help searchreplace visualblocks code insertdatetime media table paste wordcount pageembed code preview image code",
                  toolbar:
                    "  | bold italic |  alignleft aligncenter alignright |link unlink| help | forecolor backcolor |",
                }}
                onEditorChange={handleblurbEditorChange}
                onInit={() => setLoading2(false)}
              />
              <br />
              <Row>
                <Col sm={4}>
                  <div className="newsFormLabels">News Image 1</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl1}
                    upload={() => {
                      if (imageuploaddata?.imageurl1 === "") {
                        setImageType("image1");
                        setImageName("Image 1");
                        setUploadImg(true);
                        editing.current = true;
                      }
                    }}
                    delete={() => deleteImagesFromList("imageurl1")}
                  />
                </Col>
                <Col sm={4}>
                  <div className="newsFormLabels">News Image 2</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl2}
                    upload={() => {
                      if (imageuploaddata?.imageurl2 === "") {
                        setImageType("image2");
                        setImageName("Image 2");
                        setUploadImg(true);
                        editing.current = true;
                      }
                    }}
                    delete={() => {
                      imageuploaddata["imageurl2"] = "";
                      deleteImagesFromList("imageurl2");
                    }}
                  />
                </Col>
                <Col sm={4}>
                  <div className="newsFormLabels">Social Media Image</div>
                  <ImageItem
                    imageurl={imageuploaddata?.imageurl3}
                    upload={() => {
                      if (imageuploaddata?.imageurl3 === "") {
                        setImageType("image3");
                        setImageName("Social Media Image");
                        setUploadImg(true);
                        editing.current = true;
                      }
                    }}
                    delete={() => deleteImagesFromList("imageurl3")}
                  />
                </Col>
              </Row>
              <br />
              <hr />
              <Row>
                <Col sm="4" />
                <Col sm="4">
                  {id ? (
                    <Button
                      size="large"
                      block
                      loading={type === "update" && loading}
                      style={{ height: 50 }}
                      onClick={() => {
                        let values: any = form.getFieldsValue();
                        values["type"] = "update";
                        onFinish(values);
                      }}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      block
                      style={{ height: 50 }}
                      loading={type === "save" && loading}
                      onClick={() => {
                        let values: any = form.getFieldsValue();
                        values["type"] = "save";
                        onFinish(values);
                      }}
                    >
                      Save
                    </Button>
                  )}
                </Col>
                <Col sm="4">
                  <Button
                    type="primary"
                    loading={type === "publish" && loading}
                    size="large"
                    block
                    style={{ height: 50 }}
                    disabled={data?.publishedAt}
                    onClick={() => {
                      let values: any = form.getFieldsValue();
                      values["type"] = "publish";
                      onFinish(values);
                    }}
                  >
                    Publish
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm={3} xs={12}>
              <div className="newsFormBox">
                <div className="homescreen-pageBox2">
                  <div className="newsFormLabels" style={{ flex: 1 }}>
                    Perma-Link
                  </div>
                  <div>
                    {returnedNewsId || data?.id ? (
                      <IoCopyOutline
                        size={18}
                        color="green"
                        onClick={() => handleUrlCopy("copy")}
                        style={{ cursor: "pointer" }}
                      />
                    ) : null}
                  </div>
                </div>
                <Form.Item name="permalink">
                  <Input.TextArea size="large" lang="en" />
                </Form.Item>
                <div className="newsFormLabels">Priority</div>
                <Form.Item name="isHighPriority">
                  <Radio.Group
                    className="square-radio-button"
                    defaultValue={isChecked}
                    size="large"
                  >
                    <Radio value={1}>First leads</Radio>
                    <Radio value={2}>Second leads</Radio>
                    <Radio value={3}>No leads</Radio>
                  </Radio.Group>
                </Form.Item>
                <label className="newsFormLabels">on Live</label>
                <Form.Item name="Show">
                  <Switch
                    defaultChecked={
                      location?.state?.item === true ? true : false
                    }
                  />
                </Form.Item>
                <div className="newsFormLabels">Byline</div>
                <Form.Item name="author_name">
                  <Input size="large" />
                </Form.Item>
                <div className="newsFormLabels">Author Image</div>
                <ImageItem
                  imageurl={authorImg}
                  upload={() => {
                    setImageType("authorImg");
                    setUploadImg(true);
                    editing.current = true;
                  }}
                  delete={() => setAuthorImg("")}
                />
                <br />
                <div className="newsFormLabels">Publish At</div>
                <Form.Item name="publish_time" style={{ width: "100%" }}>
                  <DatePicker
                    size="large"
                    showTime
                    format={"YYYY-MM-DD HH:mm"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                {User.role.id === 4 ? (
                  <Col md={24}>
                    <div className="newsFormLabels">Author</div>
                    <Form.Item name="user_id">
                      <Input disabled defaultValue={User.username} />
                    </Form.Item>
                  </Col>
                ) : null}
                <Button
                  block
                  onClick={() => setSocialshareModal(true)}
                  disabled={data && !data.id}
                  type="primary"
                  size="large"
                >
                  Social Share Preview
                </Button>
                <br />
                <br />
                {data && data.id ? <Analytics data={data} /> : null}
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
      <br />
      {show ? (
        <PreviewModal
          open={show}
          footer={false}
          onCancel={() => setShow(false)}
          preview={() => handleUrlCopy("preview")}
          data={PreviewDatas}
        />
      ) : null}
      {imageupload ? (
        <ImageUploader
          visible={imageupload}
          field1={"image1"}
          field2={"image2"}
          field3={"image3"}
          onData={getImageUrl}
          close={() => setImageupload(false)}
        />
      ) : null}
      {medialibrarymodal ? (
        <MediaLibrary
          visible={medialibrarymodal}
          onSelect={addImage}
          onCancel={() => setMedialibrarymodal(false)}
        />
      ) : null}
      <ImagePicker
        mode="create"
        imageType={imageType}
        imageName={imageName}
        onSelect={addImage}
        onSubmit={(val: any) => addImagesToList(val)}
        visible={uploadImg}
        close={() => setUploadImg(false)}
      />
      <DataUploadIMage
        mode="update"
        imageType={imageType}
        data={data}
        onSelect={addImage}
        onSubmit={(val: any) => addImagesToList(val)}
        visible={UpdateuploadImgData}
        close={() => setUpdateUploadImgData(false)}
      />
      {socialshareModal ? (
        <SocialShare
          image={imageuploaddata?.imageurl3 || imageuploaddata.imageurl1}
          data={data}
          visible={socialshareModal}
          onCancel={() => setSocialshareModal(false)}
        />
      ) : null}
      {button ? (
        <MediaJoin
          onSubmit={(val: any) => addButtonMedia(val)}
          visible={button}
          close={() => stepButton(false)}
        />
      ) : null}
      <br />
    </div>
  );
}
export default NewForm;
