import { useState } from "react";
import { Form, Input, Button, notification, Spin } from "antd";
import { Col, Row } from "react-bootstrap";
import "../style.css";
import PageHeader from "../../../component/pageheader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../config/API";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { login } from "../../../redux/slices/userslice";
import { LoadingOutlined } from "@ant-design/icons";

function EditProfileScreen() {
  const [loading, setLoading] = useState<any>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const User = useSelector((state: any) => state.User.user);

  const userId = location?.state?.val?.id;
  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      let requestObj = {
        username: values.first_name,
        email: values.email,
        phone_number: values.phone_number,
      };
      let url = API.BASE_URL + API.PUT_UPDATE_USER + `${userId}`;

      const response = await axios.put(url, requestObj);

      let role = location?.state?.val?.role;
      let data = {
        ...response.data,
        role: role,
        Password: location?.state?.val?.Password,
      };

      dispatch(login(data));
      notification.success({
        message: "Success",
        description: "Update your details.",
      });
      setLoading(false);
      navigate("/Auth/profile");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const initialValues = {
    first_name: location?.state?.val?.username,
    phone_number: location?.state?.val?.phone_number,
    email: location?.state?.val?.email,
  };
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
  );

  const onchangeEmail = (event: any) => {
    let body = {
      email: event.target.value,
      username: event.target.value,
    };
    let Url = API.BASE_URL + API.GET_USERSEARCH;
    axios.post(Url, body).then((response) => {
      if (response.data.email.length) {
        setEmailError("This Email already exists");
      } else {
        setEmailError("");
      }
    });
  };

  return (
    <>
      <PageHeader
        title={"Edit Profile"}
        goBack={() => navigate("/Auth/profile")}
      />
      <div className="editProfile-Main">
        <div className="editProfile-Container">
          <Form onFinish={onFinish} initialValues={initialValues}>
            <Col md={{ offset: 1, span: 10 }}>
              <Row style={{ marginTop: 30 }}>
                <Col md={6}>
                  <label>User Name </label>
                  {User.role.id === 4 ? (
                    <Form.Item name={"first_name"}>
                      <Input size="large" />
                    </Form.Item>
                  ) : (
                    <Form.Item name={"first_name"}>
                      <Input size="large" disabled />
                    </Form.Item>
                  )}
                </Col>
                <Col md={6}>
                  <label>E-mail </label>
                  <Form.Item
                    name={"email"}
                    validateStatus={emailError ? "error" : ""}
                    help={emailError}
                  >
                    <Input size="large" onChange={onchangeEmail} required />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <label>Phone </label>
                  <Form.Item name={"phone_number"}>
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col md={6}></Col>
                <Col md={6}>
                  <Button
                    className="editProfile-Bttn"
                    onClick={() => navigate("/Auth/profile")}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="editProfile-Bttn"
                  >
                    {loading ? <Spin indicator={antIcon} /> : "Update"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form>
        </div>
      </div>
    </>
  );
}

export default EditProfileScreen;
