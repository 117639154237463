import { FiUploadCloud } from "react-icons/fi";
import { useState } from "react";
import "../styles.css";
import ImagePicker from "../../../component/image-picker";
import { LuMinus } from "react-icons/lu";

function ImageUploader(props: any) {
  const [isloading, setIsloading] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.imageUrl);
  const [imageUploader, setImageUploader] = useState(false);

  const addImage = (val: any) => {
    setImageUrl(val.image_url);
    props.setImage(val.image_url);
  };

  const removeImage = () => {
    setImageUrl(null);
    props.removeImage();
  }

  return (
    <section className="addScreen-ImageUploaderBox1">
      {imageUrl ? (
        <div>
          {imageUrl ? (
            <LuMinus
              size={25}
              onClick={() => removeImage()}
              className="image-delete-btn"
            />
          ) : null}
          <img src={imageUrl} className="addScreen-ImageUploaderImg1" />
        </div>
      ) : (
        <div
          className="addScreen-ImageUploaderBox2"
          onClick={() => setImageUploader(true)}
        >
          {isloading ? <div>Loading . . .</div> : <FiUploadCloud size={30} />}
          <div>Upload {props.type} Banner</div>
        </div>
      )}
      {imageUploader ? (
        <ImagePicker
          mode="create"
          imageName="Ad"
          imageType={props.type}
          onSubmit={(val: any) => addImage(val)}
          visible={imageUploader}
          close={() => setImageUploader(false)}
        />
      ) : null}
    </section>
  );
}

export default ImageUploader;
