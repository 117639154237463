import { Button, Form, Input, Modal, Select } from "antd";
import { Col, Row } from "react-bootstrap";

export default function DataUploadIMage(props: any) {
  const UploadImage = () => {
    const [form] = Form.useForm();

    const onFinish = (val: any) => {
      let obj = {
        image_url: props?.data?.image,
        type: props.imageType,
        image_desc: val?.Discription,
        image_Caption: val?.Caption,
      };
      props.onSubmit(obj);
      props.close();
    };

    return (
      <main style={{ width: "100%" }}>
        <Row>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{
              Discription: props?.data?.image1_description,
              Caption: props?.data?.image1_caption,
            }}
          >
            <Col md={24}>
              <div className="newsFormLabels">Discription</div>
              <Form.Item name="Discription">
                <Input />
              </Form.Item>
              <div className="newsFormLabels">Caption</div>
              <Form.Item name="Caption">
                <Input />
              </Form.Item>
              <Button
                className="hmScrn-SaveEditBtn"
                type="primary"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Form>
        </Row>
      </main>
    );
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      style={{ top: 20 }}
      width={500}
      footer={false}
    >
      <UploadImage />
    </Modal>
  );
}
