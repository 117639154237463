import { useState } from "react";
import Menus from "./menu.json";
import MenuItem from "./menuitem";
import { Layout } from "antd";
import ActionModal from "../component/actionModal";
import { RiLogoutCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/userslice";
import Logo from "../assets/image/logo.png";
const { Sider } = Layout;

function Siders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutModal, setLogoutModal] = useState(false);

  const logouts = () => {
    dispatch(logout(""));
    navigate("/");
  };

  return (
    <Sider
      width={100}
      style={{
        background: "#F0F2F5",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
      trigger={null}
      collapsible
    >
      <div className="Sider-logoBox" onClick={()=>navigate("/Auth")}>
        <img src={Logo} className="Sider-logo"/>
      </div>
      {Menus.map((item, index) => {
        return (
          <MenuItem
            key={index}
            icon={item.icon}
            title={item.title}
            path={item.navigate}
            logout={()=> setLogoutModal(true)}
          />
        );
      })}

      {logoutModal ? (
        <ActionModal
          visible={logoutModal}
          icon={<RiLogoutCircleLine size={50} color="#f5425d" />}
          text1={"Are You Sure You want to Logout ?"}
          onClose={() => setLogoutModal(!logoutModal)}
          onOk={() => logouts()}
        />
      ) : null}
    </Sider>
  );
}

export default Siders;
