import React, { useState } from "react";
import { Modal, Input, Form, Button, Spin } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { TfiGallery } from "react-icons/tfi";
import ImageUpload from "../../assets/image/image-holder.svg";
import "./styles.css";
import TextArea from "antd/es/input/TextArea";
import MediaLibrary from "./mediaLibrary";
import axios from "axios";
import { API } from "../../config/API";
import { IoMdCloseCircle } from "react-icons/io";

function ImageUploader(props: any) {
  const [imageurl1, setImageurl1] = useState("");
  const [imageurl2, setImageurl2] = useState("");
  const [imageurl3, setImageurl3] = useState("");
  const [loadingS3, setLoadingS3] = useState(false);
  const [type, setType] = useState("");
  const [medialibrarymodal, setMedialibrarymodal] = useState(false);
  const [form] = Form.useForm();

  const uploadImagesToS3 = async (file: any) => {
    setLoadingS3(true);
    try {
      let data = new FormData();
      data.append("file", file);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: API.BASE_URL + API.IMAGE_UPLODER,
        data: data,
      };
      let response = await axios.request(config);
      console.log(response);

      setLoadingS3(false);
      return response;
    } catch (error) {
      console.log(error);
      setLoadingS3(false);
    }
  };

  const onUpload = async (val: any, pickerName: any) => {
    const file = val.target.files[0];
    const response = await uploadImagesToS3(file);
    if (pickerName === "field1" && response) {
      setImageurl1(response.data);
    } else if (pickerName === "field2" && response) {
      setImageurl2(response.data);
    } else if (pickerName === "field3" && response) {
      setImageurl3(response.data);
    }
  };

  const handleClick = () => {
    const image1Value = form.getFieldValue("image1_description");
    const image2Value = form.getFieldValue("image2_description");
    const image3Value = form.getFieldValue("image3_description");
    const exportingData = {
      imageurl1,
      imageurl2,
      imageurl3,
      image1Value,
      image2Value,
      image3Value,
    };
    props.onData(exportingData);
  };

  const openMediaLibraryModal = (type: any) => {
    setType(type);
    setMedialibrarymodal(true);
  };

  const onSelectImageFromLibrary = (url: any) => {
    if (type === "field1") {
      setImageurl1(url);
    } else if (type === "field2") {
      setImageurl2(url);
    } else if (type === "field3") {
      setImageurl3(url);
    }
    setMedialibrarymodal(false);
  };

  let ModalImageUploader = () => {
    return (
      <Container fluid>
        <h2 className="imagetext1">Upload Images</h2>
        <br />
        <Form form={form}>
          <Row>
            <Col sm="4" xs="4">
              <div className="imageupload-box">
                {imageurl1 ? (
                  <>
                    <IoMdCloseCircle
                      onClick={() => console.log("Delete")}
                      className="image-delete-btn"
                      size={23}
                    />

                    <img
                      className="ImageUploader-image1"
                      src={imageurl1}
                      alt="img"
                    />
                  </>
                ) : loadingS3 ? (
                  <>
                    <Spin size="large" />
                    <div>Compressing...</div>
                  </>
                ) : (
                  <>
                    <img
                      src={ImageUpload}
                      alt="menu"
                      style={{ width: 60, height: 60 }}
                    />
                    <div className="imageUplaod-Txt">{props.field1}</div>
                    <Input
                      className="imgUplr-inputFile"
                      type="file"
                      onChange={(val) => onUpload(val, "field1")}
                    />
                  </>
                )}
              </div>
              <br />
              <div>
                <Button
                  onClick={() => {
                    openMediaLibraryModal("field1");
                  }}
                  icon={<TfiGallery />}
                >
                  Add from Media Library
                </Button>
              </div>
              <Row>
                <label className="edtrlMdl-dscrptnTxt">Photo Description</label>
                <Form.Item name={"image1_description"}>
                  <TextArea rows={5} />
                </Form.Item>
              </Row>
              <br />
            </Col>

            <Col sm="4" xs="4">
              <div className="imageupload-box">
                {imageurl2 ? (
                  <>
                    <IoMdCloseCircle
                      onClick={() => console.log("Delete")}
                      className="image-delete-btn"
                      size={23}
                    />
                    <img
                      className="ImageUploader-image1"
                      src={imageurl2}
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={ImageUpload}
                      alt="menu"
                      style={{ width: 60, height: 60 }}
                    />
                    <div className="imageUplaod-Txt">{props.field2}</div>
                    <Input
                      className="imgUplr-inputFile"
                      type="file"
                      onChange={(val) => onUpload(val, "field2")}
                    />
                  </>
                )}
              </div>
              <br />
              <div>
                <Button
                  onClick={() => {
                    openMediaLibraryModal("field2");
                  }}
                  icon={<TfiGallery />}
                >
                  Add from Media Library
                </Button>
              </div>
              <Row>
                <label className="edtrlMdl-dscrptnTxt">Photo Description</label>
                <Form.Item name={"image2_description"}>
                  <TextArea rows={5} />
                </Form.Item>
              </Row>
              <br />
            </Col>
            {props.field3 ? (
              <Col sm="4" xs="4">
                <div className="imageupload-box">
                  {imageurl3 ? (
                    <>
                      <IoMdCloseCircle
                        onClick={() => console.log("Delete")}
                        className="image-delete-btn"
                        size={23}
                      />
                      <img
                        className="ImageUploader-image1"
                        src={imageurl3}
                        alt="img"
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      <img
                        src={ImageUpload}
                        alt="menu"
                        style={{ width: 60, height: 60 }}
                      />
                      <div className="imageUplaod-Txt">{props.field3}</div>
                      <Input
                        className="imgUplr-inputFile"
                        type="file"
                        onChange={(val) => onUpload(val, "field3")}
                      />
                    </>
                  )}{" "}
                </div>
                <br />
                <div>
                  <Button
                    onClick={() => {
                      openMediaLibraryModal("field3");
                    }}
                    icon={<TfiGallery />}
                  >
                    Add from Media Library
                  </Button>
                </div>
                <Row>
                  <label className="edtrlMdl-dscrptnTxt">
                    Social media title
                  </label>
                  <Form.Item name={"image3_description"}>
                    <TextArea rows={5} />
                  </Form.Item>
                </Row>
                <br />
              </Col>
            ) : null}
          </Row>
        </Form>
        <br />
        <Row>
          <Col md={6}>
            <Button
              style={{ width: "100%", height: 40 }}
              onClick={() => props.close()}
            >
              Cancel
            </Button>
          </Col>
          <Col md={6}>
            <Button
              style={{ width: "100%", height: 40 }}
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleClick();
                props.close();
              }}
            >
              Upload Images
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };
  return (
    <>
      <Modal
        centered
        width={1000}
        open={props.visible}
        // onCancel={() => props.close()}
        closable={false}
        footer={false}
      >
        <ModalImageUploader />
        <br />
      </Modal>
      {medialibrarymodal ? (
        <MediaLibrary
          visible={medialibrarymodal}
          onSelect={onSelectImageFromLibrary}
          onCancel={() => setMedialibrarymodal(false)}
        />
      ) : null}
    </>
  );
}

export default ImageUploader;
