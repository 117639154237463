import "./styles.css";
import DynamicIcon from "./DynamicIcon";
import { useNavigate, useLocation } from "react-router-dom";

const MenuItem = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Navigate = (path: any) => {
    if (path === "logout") {
      props.logout();
    } else {
      navigate(path);
    }
  };
  return (
    <div
      className={
        location.pathname === props.path ? "menuItem active" : "menuItem"
      }
      onClick={() => Navigate(props.path)}
    >
      <DynamicIcon name={props.icon} size={20} color={props.title==="Log Out"?"#f5425d":"#DAA520"} />
      <div style={{padding:3}}/>
      <div style={props.title==="Log Out"?{color:"red"}:{}}>{props.title}</div>
    </div>
  );
};

export default MenuItem;
