import { useSelector } from "react-redux";
import Clock from "react-live-clock";
import ProfilePopup from "../component/profilePopup";
const Header= () => {
  const User = useSelector((state: any) => state.User.user);
  return (
    <div className="Admin-Header">
      <div className="Admin-Headertxt1">G-N</div>
      <div className="Admin-HeaderTime"> <Clock format={"h:mm:ss A"} ticking={true} /></div>
      <div style={{flex:1}}/>
      <div className="Admin-HeaderBox">
        <div>
        <div>{User.username}</div>
        <div style={{fontSize:10}}>{User.email}</div>
        </div>
        &nbsp;&nbsp;
       <div>
        <ProfilePopup/>
       </div>
      </div>
    </div>
  );
};

export default Header;
