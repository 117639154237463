import * as React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Picker from "../components/picker";
import { POST } from "../../../utils/apirequest";
import { API } from "../../../config/API";

function Home() {
  const [section1, setSection1] = useState({}) as any;
  const [section2, setSection2] = useState({}) as any;
  const [section3, setSection3] = useState({}) as any;
  const [section4, setSection4] = useState({}) as any;
  const [section5, setSection5] = useState({}) as any;
  const [section6, setSection6] = useState({}) as any;
  const [section7, setSection7] = useState({}) as any;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      let obj = {
        screen: "home",
      };
      let url = API.ADVERTISEMENT_LIST;
      let res: any = await POST(url, obj);
      if (res.status) {
        let sec1 = res.data.find(
          (item: any) => item.section === "section1" && item.desktop
        );
        let sec2 = res.data.find(
          (item: any) => item.section === "section2" && item.desktop
        );
        let sec3 = res.data.find(
          (item: any) => item.section === "section3" && item.desktop
        );
        let sec4 = res.data.find(
          (item: any) => item.section === "section4" && item.desktop
        );
        let sec5 = res.data.find(
          (item: any) => item.section === "section5" && item.desktop
        );
        let sec6 = res.data.find(
          (item: any) => item.section === "section6" && item.desktop
        );
        let sec7 = res.data.find(
          (item: any) => item.section === "section7" && item.desktop
        );
        setSection1(sec1);
        setSection2(sec2);
        setSection3(sec3);
        setSection4(sec4);
        setSection5(sec5);
        setSection6(sec6);
        setSection7(sec7);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Container fluid>
      <div>
        <Picker
          value={section1}
          screen={"home"}
          field={"section1"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          style={{ height: 150 }}
          refresh={() => loadData()}
        />
      </div>
      <div className="addScreen-header">Header</div>
      <div>
        <Picker
          value={section2}
          screen={"home"}
          field={"section2"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          style={{ height: 150 }}
          refresh={() => loadData()}
        />
      </div>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            Prioriy 2
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            Prioriy 1
          </div>
        </Col>
        <Col sm={4}>
          <Picker
            value={section3}
            screen={"home"}
            field={"section3"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ aspectRatio: 1.6 / 1 }}
            refresh={() => loadData()}
          />
          <br />
          <div className="addScreen-box" style={{ height: 220 }}>
            Poll
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            JUST IN
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            LATEST NEWS
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            TRENDING NEWS
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 150 }}>
            EDITORIAL
          </div>
        </Col>
        <Col sm={8}>
          <div className="addScreen-box" style={{ height: 150 }}>
            TODAY'S ARTICLE
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            KERALA
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            NATIONAL
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            INTERNATIONAL
          </div>
        </Col>
      </Row>
      <br />
      <div>
        <Picker
          value={section4}
          screen={"home"}
          field={"section4"}
          title={"Top Screen"}
          desc={"This add will Show above header"}
          style={{ height: 200 }}
          refresh={() => loadData()}
        />
      </div>
      <br />
      <div className="addScreen-box" style={{ height: 200 }}>
        SHORTS
      </div>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            SPORTS
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            GULF
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            CURRENCY BUSINESS
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            EDUCATION
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            CAREER
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 170 }}>
            PRAYER TIME{" "}
          </div>
          <br />
          <Picker
            value={section5}
            screen={"home"}
            field={"section5"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 300 }}
            refresh={() => loadData()}
          />
        </Col>
      </Row>
      <br />
      <div className="addScreen-box" style={{ height: 200 }}>
        LIFE STYLE
      </div>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 500 }}>
            ORGANIZATION
          </div>
        </Col>
        <Col sm={8}>
          <Row>
            <Col sm={6}>
              <div className="addScreen-box" style={{ height: 300 }}>
                TECH
              </div>
            </Col>
            <Col sm={6}>
              <div className="addScreen-box" style={{ height: 300 }}>
                AUTO MOBILE{" "}
              </div>
            </Col>
          </Row>
          <br />
          <Picker
            value={section6}
            screen={"home"}
            field={"section6"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 180 }}
            refresh={() => loadData()}
          />
        </Col>
      </Row>
      <br />
      <div className="addScreen-box" style={{ height: 200 }}>
        WEB STORIES
      </div>
      <br />
      <Row>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 250 }}>
            വിദ്യ പ്രഭാതം
          </div>
          <br />
          <div className="addScreen-box" style={{ height: 250 }}>
            TRAVEL
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 250 }}>
            TOURISM
          </div>
          <br />
          <div className="addScreen-box" style={{ height: 250 }}>
            ഞായർ പ്രഭാതം
          </div>
        </Col>
        <Col sm={4}>
          <div className="addScreen-box" style={{ height: 250 }}>
            CRIME
          </div>
          <br />
          <Picker
            value={section7}
            screen={"home"}
            field={"section7"}
            title={"Top Screen"}
            desc={"This add will Show above header"}
            style={{ height: 250 }}
            refresh={() => loadData()}
          />
        </Col>
      </Row>
      <br />
      <div className="addScreen-box" style={{ height: 250 }}>
        REELS
      </div>
      <br />
      <Row>
        <Col sm={6}>
          <div className="addScreen-box" style={{ height: 250 }}>
            IN SNAP
          </div>
        </Col>
        <Col sm={6}>
          <div className="addScreen-box" style={{ height: 250 }}>
            IN SNAP
          </div>
        </Col>
      </Row>
      <br /> <br />
    </Container>
  );
}

export default Home;
