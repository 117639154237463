import axios from "axios";

export const GetSearchNews: any = async (searchText: any) => {
  return new Promise(async function (resolve, reject) {
    try {
      const graphqlQuery = `
        query SearchNews($searchTitle: String) {
          news1: allNews(
            filters: { head: { contains: $searchTitle } }
            pagination: { page: 1, pageSize: 10000 }
            sort: "id:desc"
          ) {
            data {
              id
              attributes {
                head
                title
                body
                image
                permalink
                newsTag
                createdAt
                publishedAt
                categories {
                  data {
                    attributes {
                      category
                    }
                  }
                }
                user {
                  data {
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }

          news2: allNews(
            filters: { user: { username: { contains: $searchTitle } } }
            pagination: { page: 1, pageSize: 10000 }
            sort: "id:desc"
          ) {
            data {
              id
              attributes {
                head
                title
                body
                image
                permalink
                newsTag
                createdAt
                publishedAt
                categories {
                  data {
                    attributes {
                      category
                    }
                  }
                }
                user {
                  data {
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }

          news3: allNews(
            filters: { body: { contains: $searchTitle } }
            pagination: { page: 1, pageSize: 10000 }
            sort: "id:desc"
          ) {
            data {
              id
              attributes {
                head
                title
                body
                image
                permalink
                newsTag
                createdAt
                publishedAt
                categories {
                  data {
                    attributes {
                      category
                    }
                  }
                }
                user {
                  data {
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }

          news4: allNews(
            filters: { categories: { category: { contains: $searchTitle } } }
            pagination: { page: 1, pageSize: 10000 }
            sort: "id:desc"
          ) {
            data {
              id
              attributes {
                head
                title
                body
                image
                permalink
                createdAt
                publishedAt
                categories {
                  data {
                    attributes {
                      category
                    }
                  }
                }
                user {
                  data {
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }

          news5: allNews(
            filters: { newsTag: { contains: $searchTitle } }
            pagination: { page: 1, pageSize: 10000 }
            sort: "id:desc"
          ) {
            data {
              id
              attributes {
                head
                title
                body
                image
                permalink
                createdAt
                publishedAt
                categories {
                  data {
                    attributes {
                      category
                    }
                  }
                }
                user {
                  data {
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }

          news6: allNews(
            filters: { permalink: { contains: $searchTitle }  }
            pagination: { page: 1, pageSize: 10000 }
            sort: "id:desc"
          ) {
            data {
              id
              attributes {
                head
                title
                body
                image
                permalink
                newsTag
                createdAt
                publishedAt
                categories {
                  data {
                    attributes {
                      category
                    }
                  }
                }
                user {
                  data {
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }
        }
      `;

      axios({
        method: "post",
        url: "https://ec2.suprabhaathamapi.com/graphql",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          query: graphqlQuery,
          variables: {
            searchTitle: searchText,
          },
        },
      })
        .then((response: any) => {
          const { news1, news2, news3, news4, news5, news6 } =
            response.data.data;
          const uniqueIds = new Set();
          const combinedResults: any = [];
          const addUniqueData = (dataArray: any[]) => {
            dataArray.forEach((item: any) => {
              if (!uniqueIds.has(item.id)) {
                uniqueIds.add(item.id);
                combinedResults.push(item);
              }
            });
          };

          addUniqueData(news1.data || []);
          addUniqueData(news2.data || []);
          addUniqueData(news3.data || []);
          addUniqueData(news4.data || []);
          addUniqueData(news5.data || []);
          addUniqueData(news6.data || []);
          let obj = {
            status: true,
            data: combinedResults,
          };
          resolve(obj);
        })
        .catch((error: any) => {
          let obj = { status: false };
          reject(obj);
        });
    } catch (err) {
      let obj = { status: false };
      reject(obj);
      console.log(err);
    }
  });
};
export const GetHistoryLog: any = async (newsId: any) => {
  return new Promise(async function (resolve, reject) {
    try {
      const graphqlQuery = `
      query {
        logs(
           filters :{newsId :{eq : "${newsId}" }}
           sort: "id:asc"
           pagination: { start: 0, limit: 10 }
         ) {
           data {
             id
             attributes {
               userId
               newsId
               payload
               all_new {
                 data {
                   attributes {
                     head
                   }
                 }
               }
               user{
                 data{
                   attributes{
                     username
                   }
                 }
               }
             }
           }
         }
       }
      `;

      axios({
        method: "post",
        url: "https://ec2.suprabhaathamapi.com/graphql",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          query: graphqlQuery,
        },
      })
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          let obj = { status: false };
          reject(obj);
        });
    } catch (err) {
      let obj = { status: false };
      reject(obj);
      console.log(err);
    }
  });
};
